<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_deliveryOrderPlanList" />
      <!-- （画面名）搬入計画一覧-->
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- ユーザー取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userSuppliersSelected"
                :items="userSupplierList"
                :label="$t('label.lbl_userSupplier')"
                :hint="setSrhUserSupplier()"
                class="txt-single"
                clear-icon="mdi-close-circle"
                persistent-hint
                @change="changeUserSuppliersSelected"
                :rules="[rules.inputRequired]"
                pa-3
                :error-messages="alertSupplierMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <span class="require">*</span>
            <!-- 空白 -->
            <span class="span1"></span>
            <!-- 検索区分(ユーザー/メーカー)-->
            <div style="float: left">
              <v-btn-toggle
                mandatory
                v-model="userMakerSected"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in userMakerList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 取引先プルダウン-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="clientSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                @change="changeClient"
                :search-input.sync="searchClient"
                :rules="[this.importStatus == '02' || rules.inputRequired]"
                :error-messages="alertClientMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 搬入先工場-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="destinationyPlant"
                :items="destinationyPlantList"
                :label="$t('label.lbl_destinationyPlant')"
                :hint="setDestinationyPlant()"
                class="txt-single"
                persistent-hint
                :rules="[this.issuedDiff != '' || rules.inputRequired]"
                :error-messages="alertClientMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 搬入場所-->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="headerDeliveryDestinationy"
                :label="$t('label.lbl_deliveryDestinationy')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                maxlength="10"
                clearable
              />
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 計画コード-->
            <div class="textbox" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="headerDeliveryPlan"
                :label="$t('label.lbl_deliveryPlanCd')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>

            <!-- 異常/緊急ボタン-->
            <div
              v-if="emergencyFlag == 'on'"
              class="btn-area"
              style="position: relative; left: 19.8rem"
            >
              <v-btn color="red" id="btn-content-config" class="other-btn" @click="emergencyNotice"
                >{{ $t("btn.btn_Emergency") }}
              </v-btn>
            </div>
            <div
              v-if="emergencyFlag == 'off'"
              class="btn-area"
              style="position: relative; left: 19.8rem"
            >
              <v-btn id="btn-content-config" class="post-btn" @click="emergencyNotice"
                >{{ $t("btn.btn_Emergency") }}
              </v-btn>
            </div>
          </v-row>

          <v-row class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <div class="date-style">
              <!-- 搬入日-->
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="deliveryDate"
                :label="$t('label.lbl_deliveryDate')"
                @change="changeDateDelivery"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>

            <span class="require">*</span>
            <div class="calender-btn-area">
              <!-- カレンダー -->
              <v-menu
                v-model="dateMenuDelivery"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryDateCal"
                  @input="dateMenuDelivery = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 搬入時間From-->
            <div class="search-autocomplete first-search-item" style="float: left; width: 11rem">
              <v-autocomplete
                dense
                maxlength="2"
                v-model="deliveryTimeFrom"
                :items="deliveryTimeList"
                :label="$t('label.lbl_deliveryTime') + '（From）'"
                class="txt-single"
                item-value="text"
                persistent-hint
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 時間の間 -->
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 搬入時間To-->
            <div class="search-autocomplete first-search-item" style="float: left; width: 11rem">
              <v-autocomplete
                dense
                maxlength="2"
                v-model="deliveryTimeTo"
                :items="deliveryTimeList"
                :label="$t('label.lbl_deliveryTime') + '（To）'"
                item-value="text"
                class="txt-single"
                persistent-hint
                pa-3
                :error-messages="timeAlertMessage"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 伝票NO-->
            <div class="textbox-arrivalNo" style="float: left">
              <v-text-field
                outlined
                dense
                maxlength="50"
                v-model="shipSlipNo"
                :label="$t('label.lbl_billingSlipNo')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- ユーザー品番-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                maxlength="100"
                v-model="userProductCd"
                :items="userProductCdList"
                :label="$t('label.lbl_userProductCd')"
                :hint="setSrhUserProductCd()"
                class="txt-single"
                persistent-hint
                pa-3
                @change="changeUserProductCd"
                :search-input.sync="searchUserProductCd"
              >
                >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <!--<span class="span3"></span>-->

            <span class="item-spacer">&nbsp;</span>
            <!-- 取込状態(取込済・未取込・取消済)-->
            <!--<div style="float: left">
              <v-btn-toggle
                mandatory
                v-model="importStatus"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in importStatusList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>-->
          </v-row>

          <v-row class="search-row">
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <div class="date-style">
              <!-- データ発行日From-->
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="dataIssueDateFrom"
                :label="$t('label.lbl_dataIssueDateFrom')"
                :rules="[
                  rules.inputRequiredDataIssue(dataIssueDateFrom, dataIssueDateTo),
                  rules.yyyymmdd_dataIssue,
                ]"
                @change="changeDateFrom"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              >
              </v-text-field>
            </div>

            <div class="calender-btn-area" style="position: relative; left: 7px">
              <!-- カレンダー -->
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dataIssueDateFromCal"
                  @input="dateMenuFrom = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevFromDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextFromDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- カレンダーの間 -->
            <div class="from_to">～</div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <!-- データ発行日To-->
              <v-text-field
                outlined
                dense
                maxlength="10"
                v-model="dataIssueDateTo"
                :label="$t('label.lbl_dataIssueDateTo')"
                @change="changeDateTo"
                :rules="[
                  rules.inputRequiredDataIssue(dataIssueDateTo, dataIssueDateFrom),
                  rules.yyyymmdd_dataIssue,
                ]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              >
              </v-text-field>
            </div>

            <div class="calender-btn-area" style="position: relative; left: 7px">
              <!-- カレンダー -->
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dataIssueDateToCal"
                  @input="dateMenuTo = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevToDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextToDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <!--<span class="span1"></span> -->
            <!-- 納品書-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="deliveryPrintDate"
                :items="deliveryPrintDateList"
                :label="$t('label.lbl_delivery_print')"
                :hint="setDeliveryPrintDate()"
                class="txt-single"
                @change="changeDeliveryPrintDate"
                persistent-hint
                pa-3
              >
                >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 現品票-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="actualTicketPrintDate"
                :items="actualTicketPrintDateList"
                :label="$t('label.lbl_actualTicket_print')"
                :hint="setActualTicketPrintDate()"
                class="txt-single"
                @change="changeActualTicketPrintDate"
                persistent-hint
                pa-3
              >
                >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 差異有り-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="issuedDiff"
                :items="issuedDiffList"
                :label="$t('label.lbl_diff_include')"
                :hint="setIssuedDiff()"
                class="txt-single"
                @change="changeIssuedDiff"
                persistent-hint
                pa-3
              >
                >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <div>
              <!--ボタン領域-->
              <div class="btn-area" style="position: relative; left: 4.2rem">
                <div class="text-label" style="float: left; color: brown; width: 90px">
                  <span v-show="autoUpdateFlag == 'on'" class="nowTime"
                    >自動更新中&nbsp;&nbsp;</span
                  >
                </div>
                <!-- 更新間隔 -->
                <div
                  v-if="autoUpdateFlag == 'on'"
                  class="serch-textbox-arrivalNo"
                  style="float: left"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model="autoUpdateIntervalMin"
                    maxlength="2"
                    :label="$t('label.lbl_autoUpdateInterval_time')"
                    class="autoUpdate"
                    disabled
                    style="width: 9.6rem"
                  />
                </div>
                <div v-else class="serch-textbox-arrivalNo" style="float: left">
                  <v-text-field
                    ref="autoForm"
                    outlined
                    dense
                    v-model="autoUpdateIntervalMin"
                    maxlength="2"
                    :label="$t('label.lbl_autoUpdateInterval_time')"
                    class="autoUpdate"
                    :rules="[rules.isNumber, rules.autoUpdateMinMaxCheck]"
                    :error-messages="autoUpdateIntervalMinMessage"
                    clear-icon="mdi-close-circle"
                    clearable
                    style="width: 9.6rem"
                    @blur="onBlur"
                  />
                </div>

                <!-- 自動更新ボタン-->
                <v-btn id="btn-pack" class="post-btn" @click="autoUpdate">
                  {{ $t("btn.btn_autoUpdate") }}
                </v-btn>
              </div>
            </div>
            <!-- 空白 -->
            <!-- </div> -->
          </v-row>

          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索用ボタン -->
                <v-btn :class="norClass" @click="getserach('nor')">{{ $t("btn.btn_nor") }}</v-btn>
                <v-btn :class="notImportedClass" @click="getserach('notImported')">{{
                  $t("btn.btn_notImported")
                }}</v-btn>
                <v-btn :class="importedClass" @click="getserach('imported')">{{
                  $t("btn.btn_imported")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area">
                <!--再取込-->
                <v-btn
                  id="btn-content-config"
                  class="other-btn"
                  color="primary"
                  :disabled="inputList.length == '0' || searchDiv == '01' || searchDiv == '03'"
                  @click="checkReUpload()"
                  >{{ $t("btn.btn_re_upload") }}</v-btn
                >
                <!-- 計画出力ボタン-->
                <v-btn
                  id="btn-content-config"
                  class="other-btn download-btn"
                  color="primary"
                  :disabled="downloadFlg"
                  @click="getOutPut()"
                  >{{ $t("btn.btn_delivery_plan_list_output") }}</v-btn
                >
                <!--削除ボタン-->
                <v-btn
                  id="btn-content-config"
                  class="other-btn"
                  color="primary"
                  :disabled="inputList.length == '0' || searchDiv == '02' || searchDiv == '03'"
                  @click="checkDelete('btn_delete')"
                  >{{ $t("btn.btn_delete") }}</v-btn
                >
                <!-- 復元ボタン -->
                <v-btn
                  class="post-btn"
                  @click="reImport()"
                  :disabled="inputList.length == '0' || searchDiv == '01' || searchDiv == '02'"
                >
                  {{ $t("btn.btn_restoration") }}
                </v-btn>
                <!--新規登録ボタン-->
                <v-btn class="post-btn" @click="initRegist()">{{ $t("btn.btn_iniRegist") }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right; padding-left: 20px">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
              <div style="float: right">
                <v-btn class="post-btn cargos_btn" @click="cargosopen()">{{
                  $t("btn.btn_cargos")
                }}</v-btn>
              </div>
            </div>
            <!-- ページング数 -->
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          :class="tableData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          :options.sync="sortOptions"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item }">
            <div :style="checkBtnStyle">
              <input
                type="checkbox"
                v-model="item.check"
                :value="listCheckbox"
                style="transform: scale(2)"
              />
            </div>
          </template>
          <!-- ダウンロードリンク -->
          <template v-slot:[`item.stowage`]="{ item }">
            <!-- <a href="https://dev-cargos.nikkon.jp/" target="_blank"> -->
            <div>{{ item.stowage }}</div>
            <!-- </a> -->
          </template>
          <template v-slot:[`item.loading`]="{ item }">
            <!-- <a href="https://dev-cargos.nikkon.jp/" target="_blank"> -->
            <div>{{ item.loading }}</div>
            <!-- </a> -->
          </template>
          <!-- ヘッダー部分 -->
          <!-- ユーザー品番/品名 -->
          <template v-slot:[`item.userProductCd`]="{ item }">
            <div v-if="item.userProductCodeName.length > 20">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                    {{ item.userProductCd }}
                  </div>
                  <div class="text-overflow" style="max-width: 200px">
                    {{ item.userProductCodeName }}
                  </div>
                </template>
                <div>{{ item.userProductCd }}</div>
                <div>{{ item.userProductCodeName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.userProductCd }}</div>
              <div>{{ item.userProductCodeName }}</div>
            </div>
          </template>
          <!-- 伝票No. -->
          <template v-slot:[`item.billingSlipNo`]="{ item }">
            <div class="listNo-style">{{ item.billingSlipNo }}</div>
          </template>

          <!-- メーカーコード/メーカー取引先名 -->
          <template v-slot:[`header.makerCd`]="{}">
            {{ $t("label.lbl_makerCd") }}<br />
            {{ $t("label.lbl_makerCdName") }}
          </template>
          <template v-slot:[`item.makerCd`]="{ item }">
            <div>
              <div>{{ item.makerCd }}</div>
              <div>{{ item.makerName }}</div>
            </div>
          </template>

          <!-- メーカー工場 -->
          <template v-slot:[`header.makerPlant`]="{}">
            {{ $t("label.lbl_makerName") }}<br />
            工場
          </template>

          <!-- 搬入時刻-->
          <template v-slot:[`header.deliveryTime`]="{}">
            {{ $t("label.lbl_delivery") }}<br />
            {{ $t("label.lbl_time") }}
          </template>

          <!-- データ発行日時-->
          <template v-slot:[`header.dataIssueDate`]="{}">
            {{ $t("label.lbl_deta") }}<br />
            <span style="margin-left: 30px">発行日時</span>
          </template>

          <!-- 納品書 -->
          <template v-slot:[`header.deliveryPrintDate`]="{}">
            {{ $t("label.lbl_delivery_print") }}<br />
            <span style="margin-left: 30px">発行日時</span>
          </template>

          <!-- 現品票 -->
          <template v-slot:[`header.actualTicketPrintDate`]="{}">
            {{ $t("label.lbl_actualTicket_print") }}<br />
            <span style="margin-left: 30px">発行日時</span>
          </template>

          <!-- 詳細ボタン -->
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn small @click="openUpdate(item)" text :style="btnStyle">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-dialog v-model="dataOutPutDialog" :max-width="350" origin:top>
          <!-- データ出力用ダイアログ -->
          <v-card>
            <v-card-title id="sub-bar">
              <span id="lbl-screen-name">{{ $t("label.lbl_delivery_plan_list_output") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-form ref="numIssueVali" lazy-validation>
                  <v-row class="search-row" style="margin-bottom: 5px">
                    <!--データ形式-->
                    <div class="search-autocomplete first-search-item" style="width: 270px">
                      <v-autocomplete
                        dense
                        v-model="dataFormatSelected"
                        :items="dataFormatList"
                        :label="$t('label.lbl_outputFormatDiv')"
                        :rules="[rules.dataFormatRules]"
                        class="txt-single"
                        :hint="setOutputFormatDiv()"
                        persistent-hint
                      >
                        <template slot="item" slot-scope="data">
                          <span class="auto-txt-size"> {{ data.item.text }} </span></template
                        >
                      </v-autocomplete>
                    </div>
                    <span class="require">*</span>
                    <!--データ形式-->
                  </v-row>
                  <v-row style="margin-bottom: 5px">
                    <div class="search-row-exeBtn">
                      <div class="btn-area">
                        <!-- 閉じるボタン -->
                        <v-btn
                          color="primary"
                          id="btn-search"
                          class="other-btn"
                          @click="closeDetailsInfo()"
                        >
                          {{ $t("btn.btn_close") }}</v-btn
                        >

                        <!-- 出力ボタン -->
                        <v-btn
                          color="primary"
                          id="btn-search"
                          class="other-btn"
                          @click="outPutClick()"
                        >
                          {{ $t("btn.btn_outPutData") }}</v-btn
                        >
                      </div>
                    </div>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- キュー状況ダイアログ -->
        <v-dialog v-model="dialogQueInfoStatus" :max-width="800" persistent="" no-click-animation>
          <v-card>
            <v-card-title id="sub-bar">
              <span id="lbl-screen-name">{{ $t("label.lbl_actualTicket_print_download") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <!-- キュー状況テーブルデータ -->
                <v-data-table
                  id="QueInfoStatusData"
                  fixed-header
                  :headers="queInfoHeader"
                  :items="queInfoList"
                  disable-sort
                  :hide-default-footer="true"
                >
                  <!-- 指示作成状況メッセージ -->
                  <template v-slot:[`item.queInfoMessage`]="{ item }">
                    <span>
                      <!-- 指示作成状況メッセージを表示 -->
                      {{ item.queInfoMessage }}
                    </span>
                  </template>
                </v-data-table>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!--ボタン領域-->
              <div class="search-row-exeBtn">
                <div class="btn-area">
                  <!-- 閉じるボタン -->
                  <v-btn class="other-btn" @click="dialogQueInfoStatus = false">
                    {{ $t("btn.btn_close") }}
                  </v-btn>
                </div>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { getParameterProcurement } from "../../assets/scripts/js/GetParameterProcurement";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import { postParameter } from "../../assets/scripts/js/PostParameter";

export default {
  name: appConfig.MENU_ID.BIZ_DELIVERY_INSTRUCTIONS_SEARCH,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    NavBar,
    ConfirmDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_100: false,
    rules: {
      // item40Required: (value) => !!value || i18n.tc("check.chk_select_item40"),
      // displayRequired: (value) => !!value || i18n.tc("check.chk_select_displayPeriod"),
      // // 更新間隔に何も入力せずフォーカスアウトしてもメッセージ表示しない
      // // autoUpdateReset: (value) => !!value || this.autoForm.reset(),
      autoUpdateMinMaxCheck: (value) =>
        !value || (value > 0 && value < 100) || i18n.tc("check.chk_limitLength_1-99"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      yyyymmdd_dataIssue: (value) =>
        !value ||
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      isNumber: (value) =>
        !value ||
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "").replaceAll("¥", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      autoUpdateInputCheck: function (value) {
        let num = Number(value);
        if (num < 1 || num > 99) {
          return i18n.tc("check.chk_limitLength_1-99");
        }
        // return true;
      },
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      inputRequiredDataIssue: (value1, value2) => !value2 || !!value1 || i18n.tc("check.chk_input"),
      //データ出力ダイアログ
      dataFormatRules: (value) => !!value || i18n.tc("check.chk_outputFormatDiv"),
    },

    userSuppliersSelected: "", // ユーザー取引先
    userMakerSected: "01", // 検索区分(ユーザー/メーカー)
    clientSelected: "", // 取引先
    destinationyPlant: "", // 搬入先工場
    headerDeliveryDestinationy: "", //搬入場所
    headerDeliveryPlan: "", //計画コード
    deliveryDate: "", //納入日
    deliveryDateCal: "", //納入日カレンダー
    dateMenuDelivery: false,
    deliveryTimeFrom: "", //搬入時間From
    deliveryTimeTo: "", //搬入時間To
    shipSlipNo: "", //伝票NO
    importStatus: "", // 取込状態
    dataIssueDateFrom: "", //入荷予定日From
    dataIssueDateFromCal: "", //入荷予定日Fromカレンダー
    dateMenuFrom: false,
    dataIssueDateTo: "", //入荷予定日To
    dataIssueDateToCal: "", //入荷予定日Toカレンダー
    dateMenuTo: false,
    userProductCd: "", //ユーザー品番
    autoUpdateInterval: "", // 更新間隔
    autoUpdateIntervalMin: null,
    autoUpdateMessage: "",
    autoUpdateIntervalMinMessage: "",
    interval4times: "",
    autoUpdateFlag: "off",
    emergencyFlag: "off",
    transitionFlg: "",
    searchDiv: "",
    userClientCd: "",
    issuedDiff: "",
    deliveryPrintDate: "", //納品書
    actualTicketPrintDate: "", //現品票
    //パラメータ引き継ぎ用
    searchClient: "",
    searchDataIssueDate: "",
    searchDeliveryDateTime: "",
    searchBillingSlipNo: "",
    searchReplacementDivision: "",
    searchImportStatus: "",
    searchUserProductCd: "",

    anomalyNumber: 0,
    inputListFlag: false,
    totalCount: 0, // 総件数
    openMenu: null, // メニュー
    suppliersSelected: "", // 取引先コードリスト
    selectHintClient: "",
    productHint: "",
    isAllSelected: "", //全選択チェックボックス
    listCheckbox: [1, 2], // 一覧選択チェックボックス
    inputList: [], // 一覧
    deleteList: [],
    supplierList: [],
    destinationyPlantList: [],
    userSupplierList: [],
    userProductCdList: [],
    deliveryTimeList: [],
    defaultData: [], // 初期データ
    issuedDiffList: [],
    deliveryPrintDateList: [], //納品書
    actualTicketPrintDateList: [], //現品票

    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    itemsPerPage: 500,
    itemsPerPageBtn: "500",

    //出荷保証情報表示項目
    cargosValue: "",
    cargosValueList: [],

    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
    },

    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    alertSupplierMessage: "",
    alertClientMessage: "",
    timeAlertMessage: "",

    updateDialogMessage: false, // 戻るボタンダイアログ
    backMessage: "", // 戻るボタンダイアログメッセージ
    // ソート
    sortItem: "",
    sortOptions: {},

    //データ出力時ダイアログ(初期値)
    dataOutPutDialog: false,

    // 出力形式区分
    dataFormatSelected: "01",

    // キュー状況ダイアログ
    dialogQueInfoStatus: false,
    // リクエストID
    requestId: "",
    // タイムID
    timeId: "",
    // キュー状況ヘッダー
    queInfoHeader: [
      {
        text: i18n.tc("label.lbl_message"),
        value: "queInfoMessage",
        align: "left",
        width: "15 % ",
      },
    ],
    // キュー状況データ
    queInfoList: [],

    chengeSortBy: "",
    chengeAscdesc: "",
    btnid: "",
    defaultbtn: "get-btn cargos_btn btn_search",
    norClass: "get-btn cargos_btn btn_search",
    notImportedClass: "get-btn cargos_btn btn_search",
    importedClass: "get-btn cargos_btn btn_search",
    tableData: "tableData",
    checkFlg1: false,
    btnStyle: "",
    checkBtnStyle: "",
    // ヘッダ
    headerItems: [
      // チェックボックス
      {
        text: "選択",
        value: "check",
        width: "50px",
        align: "center",
        sortable: false,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "70px",
        align: "center",
        sortable: false,
      },
      // メーカーコード
      {
        text: i18n.tc("label.lbl_makerCd"),
        value: "makerCd",
        width: "230px",
        align: "left",
        sortable: false,
      },
      // メーカー工場
      {
        text: i18n.tc("label.lbl_userPlant"),
        value: "makerPlant",
        width: "120px",
        align: "left",
        sortable: true,
      },
      // 搬入時刻
      {
        text: i18n.tc("label.lbl_deliveryTime"),
        value: "deliveryTime",
        width: "75px",
        align: "left",
        sortable: true,
      },

      // 搬入場所
      {
        text: i18n.tc("label.lbl_deliveryDestinationy"),
        value: "deliveryDestinationy",
        width: "130px",
        align: "left",
        sortable: true,
      },
      // 計画コード
      {
        text: i18n.tc("label.lbl_deliveryPlanCd"),
        value: "deliveryPlan",
        width: "130px",
        align: "left",
        sortable: true,
      },
      // ユーザー品番/品名
      {
        text: i18n.tc("label.lbl_userProductCd") + "/" + i18n.tc("label.lbl_productCodeName"),
        value: "userProductCd",
        width: "230px",
        align: "left",
        sortable: true,
      },
      // 付帯品番
      {
        text: i18n.tc("label.lbl_productAccessoryCd"),
        value: "productAccessoryCd",
        width: "130px",
        align: "left",
        sortable: true,
      },
      // 伝票No
      {
        text: i18n.tc("label.lbl_billingSlipNo"),
        value: "billingSlipNo",
        width: "215px",
        align: "left",
        sortable: true,
      },
      // 指示数
      {
        text: i18n.tc("label.lbl_instNum"),
        value: "instNum",
        width: "110px",
        align: "right",
        sortable: true,
      },
      // 出庫
      {
        text: i18n.tc("label.lbl_exiting"),
        value: "exiting",
        width: "100px",
        align: "right",
        sortable: false,
      },
      // 出荷
      {
        text: i18n.tc("label.lbl_shipping"),
        value: "shipping",
        width: "100px",
        align: "right",
        sortable: false,
      },
      // 納品書
      {
        text: i18n.tc("label.lbl_delivery_print"),
        value: "deliveryPrintDate",
        width: "140px",
        align: "center",
        sortable: true,
      },
      // 現品票
      {
        text: i18n.tc("label.lbl_actualTicket_print"),
        value: "actualTicketPrintDate",
        width: "140px",
        align: "center",
        sortable: true,
      },
      // データ発行日時
      {
        text: i18n.tc("label.lbl_deta"),
        value: "dataIssueDate",
        width: "140px",
        align: "center",
        sortable: true,
      },
    ],
    freeReasonList: [],
    importStatusList: [],
    userMakerList: [],
    //データ形式プルダウン
    dataFormatList: [],
    cargosCheck: null,
  }),
  created() {
    this.init();
  },

  methods: {
    //ユーザーメーカー切り替え処理
    chengeUserMakerSected(newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
      }

      this.clientSelected = "";
      this.supplierList = [];
    },
    onBlur() {
      if (this.autoUpdateIntervalMin == "" || this.autoUpdateIntervalMin == null) {
        this.$refs.autoForm.reset();
      }
    },
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_100 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.getApiList(false);
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false);
    },

    changeUserSuppliersSelected() {
      // 取引先を変更する場合はクリアする
      this.clientSelected = "";
      this.supplierList = [];
      this.destinationyPlant = "";
      this.destinationyPlantList = [];
      this.userProductCd = "";
      this.userProductCdList = [];

      // ユーザー取引先が存在する場合
      if (this.userSuppliersSelected) {
        for (let i = 0; i < this.userSupplierList.length; i++) {
          if (this.userSupplierList[i].value == this.userSuppliersSelected) {
            this.userClientCd = this.userSupplierList[i].clientCd;
          }
        }
        // ローディング画面表示ON
        this.loadingCounter = 1;
        const destinationyPlantList = getParameterProcurement.getBizCarryCompCd(
          this.userClientCd,
          dateTimeHelper.convertUTC(this.deliveryDate)
        );
        return Promise.all([destinationyPlantList])
          .then((result) => {
            // 画面の初期値を設定します。
            this.destinationyPlantList = result[0];
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      }
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const supplierList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      return Promise.all([supplierList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.userSupplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 取引先API接続
    getSupplier() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.isGetClient = "1";

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_RELATEDBIZ, config)
          .then((response) => {
            // console.debug("getSupplier() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            if (jsonData.resCom.resComCode == "000") {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  text: `${row.clientCd}` + `（${row.relatedPartyName}）`,
                  value: row.relatedPartySid,
                  name: row.relatedPartyName,
                });
              });
              this.supplierList = list;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("getSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;

            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // ユーザー・メーカー区分
      const userMakerList = getParameter.getCodeMst(appConfig.CODETYPE.USER_MAKER_SELECT);
      // 搬入計画時間
      const deliveryTimeList = getParameter.getCodeMst(appConfig.CODETYPE.USER_DELIVERY_TIME);
      // 取込状態 正常・未取込
      const importStatusList = getParameter.getCodeMst(
        appConfig.CODETYPE.IMPORT_STATUS_NORMAL,
        appConfig.CODETYPE.IMPORT_STATUS_CANCEL
      );

      //積付、積込の値取得
      const cargosList = getParameter.getCodeMst(appConfig.CODETYPE.CARGOS_KBN);

      // 差異有りの値取得
      const issuedDiffList = getParameter.getCodeMst(appConfig.CODETYPE.DIFFERENCE_DIV);

      // 納品書の値取得
      const deliveryPrintDateList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_INVOICE_DIV);

      // 現品票の値取得
      const actualTicketPrintDateList = getParameter.getCodeMst(appConfig.CODETYPE.OUT_INVOICE_DIV);

      //データ出力形式
      const dataFormatDivList = getParameter.getCodeMst(
        appConfig.CODETYPE.DELIVERYORDER_OUTPUT_DIV
      );

      return Promise.all([
        userMakerList,
        deliveryTimeList,
        importStatusList,
        cargosList,
        issuedDiffList,
        deliveryPrintDateList,
        actualTicketPrintDateList,
        dataFormatDivList,
      ])
        .then((result) => {
          this.userMakerList = result[0];
          this.deliveryTimeList = result[1];
          this.importStatusList = result[2];
          this.cargosValue = result[3][0].text;
          var count = result[1][this.deliveryTimeList.length - 1].value;
          for (var i = 0; i < 23; i++) {
            this.deliveryTimeList.push({
              text: String(Number(count) + Number(i)),
              value: String(Number(count) + Number(i)),
            });
          }
          this.issuedDiffList = result[4];
          this.deliveryPrintDateList = result[5];
          this.actualTicketPrintDateList = result[6];
          this.dataFormatList = result[7];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          if (this.inputListFlag && this.$route.params.transitionFlg == true) {
            this.getApiList(true);
          }
          if (this.$route.params.linkKbn && this.$route.params.transitionFlg == true) {
            //異常画面link押下時
            this.getApiListLink();
          }
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * link用検索処理
     */
    getApiListLink() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // ユーザー取引先
      config.params.userClientSid = this.$route.params.userClientSid;

      // 搬入日
      if (this.deliveryDate) {
        config.params.deliveryDate = this.deliveryDate;
      }
      config.params.outListNo = this.shipSlipNo;

      //検索区分
      config.params.searchKbn = "02";

      //ユーザー営業所SID
      config.params.userOfficeSid = sessionStorage.getItem("sales_office_sid");

      if (this.page >= 2 && this.inputList.length == 0) {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        config.params.reqComPageIndex = this.page;
      }
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_DELIVERY_INSTRUCTIONS_SEARCH, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getApiList(checkBoxFlag) Response", response); // 成功
            const list = [];
            if (jsonData.resCom.resComCode == "000") {
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              // 搬入計画リストを取得
              jsonData.resIdv.deliveryInstructions.forEach((row) => {
                list.push({
                  check: false,
                  outListSubSid: row.outListSubSid, // 出荷伝票明細SID
                  userClientSid: row.userClientSid, // user取引先SID
                  deliveryDatetime: row.deliveryDatetime, // 納入日時
                  billingSlipNo: row.outListNo, // 出荷伝票No.
                  replacementDiv: row.replacementDiv, // 差替区分
                  deliveryCd: row.deliveryCd, // 納入先CD
                  makerCd: row.clientCd, // メーカーコード
                  makerName: row.clientName, // メーカー取引先名称
                  makerPlant: row.clientPlantCd, // メーカー工場
                  deliveryTime: row.deliveryTime.substr(0, 2) + ":" + row.deliveryTime.substr(2, 4), // 搬入時間
                  deliveryDestinationy: row.deliveryPlaceCd, // 搬入場所
                  deliveryPlan: row.planCd, // 計画コード
                  userItemSid: row.userItemCd, // ユーザー品番SID
                  userProductCd: row.userItemCd, // ユーザー品番
                  userProductCodeName: row.userName, // ユーザー品名
                  productAccessoryCd: row.userItemSubCd, // 付帯品番
                  instNum: String(commonUtil.formatToCurrency(Number(row.stock))), // 指示数
                  exiting: String(commonUtil.formatToCurrency(Number(row.issueStock))), // 出庫実績数
                  stowage: this.cargosValue,
                  loading: this.cargosValue,
                  shipping: String(commonUtil.formatToCurrency(Number(row.shippingStock))), // 出荷実績数
                  dataIssueDate: row.dataCreateDateTime, // データ発行日
                  deliveryPrintDate: row.deliveryPrintDate, // 納品書
                  actualTicketPrintDate: row.actualTicketPrintDate, // 現品票
                  transactionId: row.transactionId, // トランザクションid
                  autoNo2: row.autoNo2, // 自動連番2
                  updateDatetime: row.updateDatetime, // 更新日時
                  makerClientSid: row.makerClientSid,
                });
                // this.headerDeliveryDestinationy = row.deliveryPlaceCd; //搬入場所
                // this.headerDeliveryPlan = row.planCd; //計画コード
                //ユーザー取引先
                this.userSuppliersSelected = row.relatedPartySid;
                //取引先
                this.clientSelected = row.clientCd;
                const clientlist = [];
                clientlist.push({
                  text: `${row.clientCd}` + `（${row.clientName}）`,
                  value: row.clientCd,
                  name: row.clientName,
                });
                this.supplierList = clientlist;
                //搬入先工場
                this.destinationyPlant = row.carryCompCd;
                //ボタンの背景色初期化
                const background = this.defaultbtn;
                if (row.searchKbn == "01") {
                  this.norClass = background + " btn_search_nor";
                  this.tableData = "tableData norTableData";
                  this.btnid = "nor";
                  this.btnStyle = "";
                  this.checkBtnStyle = "";
                } else if (row.searchKbn == "02") {
                  this.notImportedClass = background + " btn_search_notImported";
                  this.tableData = "tableData notImportedTableData";
                  this.btnid = "notImported";
                  this.btnStyle = "display:none";
                  this.checkBtnStyle = "";
                } else if (row.searchKbn == "03") {
                  this.importedClass = background + " btn_search_imported";
                  this.tableData = "tableData importedTableData";
                  this.btnid = "imported";
                  this.btnStyle = "";
                  this.checkBtnStyle = "";
                }
                this.importStatus = row.searchKbn;
              });
              this.searchDiv = this.importStatus;
              this.inputList = list;
              if (0 < list.length) {
                this.inputListFlag = true;
              }
              resolve(response);
            } else {
              // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              // タスクを終了します（失敗）。
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * プルダウンチェンジイベント
     */
    changeClient(value) {
      // 取引先を選択時
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.selectHintClient = val.text;
        this.searchClient = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.selectHintClient = "";
      } else {
        // 処理なし
      }
    },

    changeUserProductCd(value) {
      // 既存のユーザー品番/品名を選択時
      let val = this.userProductCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.searchUserProductCd = val.text;
      }
      this.userProductCdList = this.userProductCdList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHint = "";
      } else {
        // 処理なし
      }
    },

    changeIssuedDiff(value) {
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.issuedDiff = "";
      } else {
        // 処理なし
      }
    },

    // 納品書
    changeDeliveryPrintDate(value) {
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.deliveryPrintDate = "";
      } else {
        // 処理なし
      }
    },

    // 現品票
    changeActualTicketPrintDate(value) {
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.actualTicketPrintDate = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;
        }
        this.isAllSelected = false;
      }
    },
    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },
    /**
     * 自動更新
     */
    autoUpdate() {
      // メッセージを空にする
      if (this.autoUpdateIntervalMin == null || this.autoUpdateIntervalMin == "") {
        // デフォルト時間を10分に設定
        this.autoUpdateIntervalMin = "10";
      }
      this.autoUpdateIntervalMinMessage = "";
      // 更新間隔が空の場合
      if (this.autoUpdateIntervalMin == null || this.autoUpdateIntervalMin == "") {
        this.autoUpdateMessage = "";
        this.autoUpdateMessage = i18n.tc("check.chk_autoUpdate");
        return;
      }
      // 更新間隔が1~99以外は自動更新が実行されないよう
      if (this.autoUpdateIntervalMin < 1 || this.autoUpdateIntervalMin > 99) {
        return;
      }
      // const convertStringToNumber = (n) => {
      //   if (typeof nthis.item40SelectedMessage = ""; !== "string" || n === "") return false;
      //   const num = Number(n);
      //   // 数値に変換できない場合はNaNが返る
      //   return isNaN(num) ? false : num;
      // };

      // 更新間隔が数字以外の場合は自動更新が実行されないよう
      if (!Number.isInteger(this.convertStringToNumber(this.autoUpdateIntervalMin))) {
        // this.item40SelectedMessage = "";
        return;
      }

      // 自動更新フラグ
      if (this.autoUpdateFlag == "on") {
        // 自動更新停止
        this.autoUpdateFlag = "off";
        clearInterval(this.interval4times);
        console.log("clearInterval");
      } else {
        this.autoUpdateFlag = "on";
        // 入力された分をミリ秒単位に変更
        let autoUpdateInterval = this.autoUpdateIntervalMin * 60000;

        // let interval4times = setInterval(this.apigetStock, this.autoUpdateInterval);
        // this.interval4times = setInterval(() => {
        //   this.getNumberBiz(officeSid);
        // }, autoUpdateInterval);
        this.interval4times = setInterval(() => {
          if (this.btnid != "") {
            this.getApiList(true);
          }
          this.getNumberBiz(sessionStorage.getItem("sales_office_sid"));
        }, autoUpdateInterval);
        // document.getElementById("get-search").click();
        console.log("interval4times: " + this.interval4times);
      }
    },

    /**
     * 出荷保証画面を表示
     */
    cargosopen() {
      console.log(this.cargosCheck);
      if (this.cargosCheck != null && this.cargosCheck.window != null) {
        console.log(this.cargosCheck.window);
        this.cargosCheck.focus();
      } else {
        this.cargosCheck = window.open("https://dev-cargos.nikkon.jp/", "cargos");
        console.log(this.cargosCheck);
      }
    },

    getserach(val) {
      this.btnid = val;
      if (this.btnid == "nor") {
        this.importStatus = "01";
      } else if (this.btnid == "notImported") {
        this.importStatus = "02";
      } else if (this.btnid == "imported") {
        this.importStatus = "03";
      }
      this.getApiList(true);
    },
    /**
     *  検索ボタン押下処理
     */
    getApiList(checkBoxFlag) {
      //  入力チェック結果フラグ
      let inputCheckResult = false;
      let check = 0;
      this.alertSupplierMessage = "";
      this.alertClientMessage = "";
      this.alertMessage = "";
      this.timeAlertMessage = "";

      // 入力チェック結果フラグ
      if (this.userSuppliersSelected == undefined || this.userSuppliersSelected == null) {
        this.alertSupplierMessage = i18n.tc("check.chk_input");
        return;
      }
      // 取引先存在チェック
      if (this.importStatus != "02") {
        if (this.clientSelected == undefined || this.clientSelected == null) {
          this.alertClientMessage = i18n.tc("check.chk_input");
          check++;
        }
      }

      if (
        // FromToの日付、入力チェック
        new Date(this.dataIssueDateFrom).getTime() > new Date(this.dataIssueDateTo).getTime()
      ) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }
      if (
        // FromToの時間、入力チェック
        this.deliveryTimeFrom > this.deliveryTimeTo
      ) {
        this.timeAlertMessage = i18n.tc("check.chk_inputFromDate");
        check++;
      }
      if (check == 0 && this.btnid != "") {
        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するためこのようなコードとなっている。
         */
        if (this.$route.params.searchSuppliersSelected && this.checkFlg) {
          // 登録画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。

          inputCheckResult = this.$refs.form.validate();
        }
        // 画面遷移フラグをfalseに戻す
        this.checkFlg = false;

        if (inputCheckResult) {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";

          // ローディング画面表示ON
          this.loadingCounter = 1;

          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();
          // ユーザー取引先
          config.params.userClientSid = this.userSuppliersSelected;
          // 検索区分
          if (this.userMakerSected) {
            config.params.standard = this.userMakerSected;
          }
          // 取引先コード
          if (this.clientSelected) {
            config.params.clientSid = this.clientSelected;
            if ("02" === this.userMakerSected) {
              config.params.makerClientCd = this.supplierList.find(
                (s) => s.value === this.clientSelected
              ).clientCd;
            }
          }
          // 搬入先工場
          if (this.destinationyPlant) {
            config.params.deliveryToCd = this.destinationyPlant;
          }
          // 搬入場所
          if (this.headerDeliveryDestinationy) {
            config.params.deliveryPlaceCd = this.headerDeliveryDestinationy;
          }
          // 計画
          if (this.headerDeliveryPlan) {
            config.params.planCd = this.headerDeliveryPlan;
          }
          // 搬入日
          if (this.deliveryDate) {
            config.params.deliveryDate = this.deliveryDate;
          }
          // 搬入時間（From）
          if (this.deliveryTimeFrom) {
            config.params.deliveryTimeFrom = this.deliveryTimeFrom;
          }
          // 搬入時間（To）
          if (this.deliveryTimeTo) {
            config.params.deliveryTimeTo = this.deliveryTimeTo;
          }
          // 取込状態
          // if (this.importStatus) {
          //   config.params.capturingState = this.importStatus;
          // }
          if (this.btnid == "nor") {
            config.params.capturingState = "01";
          } else if (this.btnid == "notImported") {
            config.params.capturingState = "02";
          } else if (this.btnid == "imported") {
            config.params.capturingState = "03";
          }
          // データ発行日(From)
          if (this.dataIssueDateFrom) {
            //config.params.outdateIssueFrom = dateTimeHelper.convertUTC(this.dataIssueDateFrom);
            config.params.dateIssueFrom = this.dataIssueDateFrom;
          }
          // データ発行日(To)
          if (this.dataIssueDateTo) {
            // var arr = this.dataIssueDateTo + " 23:59:59.999";
            // config.params.dateIssueTo = dateTimeHelper.convertUTC(arr);
            config.params.dateIssueTo = this.dataIssueDateTo;
          }
          // 出荷伝票No
          if (this.shipSlipNo) {
            config.params.outListNo = this.shipSlipNo;
          }
          // ユーザー品番（SID）
          if (this.userProductCd) {
            config.params.userItemSid = this.userProductCd;
          }
          // 差異有りフラグ
          if (this.issuedDiff) {
            config.params.issuedFlg = this.issuedDiff;
          }
          // 納品書フラグ
          if (this.deliveryPrintDate) {
            config.params.deliveryPrintDateFlg = this.deliveryPrintDate;
          }
          // 現品票フラグ
          if (this.actualTicketPrintDate) {
            config.params.actualTicketPrintDateFlg = this.actualTicketPrintDate;
          }
          // ページャー処理
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageLimit = this.itemsPerPage;
          //検索区分
          config.params.searchKbn = "01";
          // ソート
          if (this.sortItem != "") {
            if (this.btnid == "nor" || this.btnid == "imported") {
              this.sortItem = appConfig.P_SUP_001_SORT_T601[this.chengeSortBy].replaceAll(
                "{0}",
                this.chengeAscdesc
              );
            } else {
              this.sortItem = appConfig.P_SUP_001_SORT_T600[this.chengeSortBy].replaceAll(
                "{0}",
                this.chengeAscdesc
              );
            }
            config.params.reqComSortItem = this.sortItem;
          }
          //ボタンの背景色初期化
          const background = this.defaultbtn;
          this.norClass = background;
          this.notImportedClass = background;
          this.importedClass = background;
          this.tableData = "tableData";
          if (this.btnid == "nor") {
            this.norClass = background + " btn_search_nor";
            this.tableData = "tableData norTableData";
            this.btnStyle = "";
            this.checkBtnStyle = "";
          } else if (this.btnid == "notImported") {
            this.notImportedClass = background + " btn_search_notImported";
            this.tableData = "tableData notImportedTableData";
            this.btnStyle = "display:none";
            this.checkBtnStyle = "";
          } else if (this.btnid == "imported") {
            this.importedClass = background + " btn_search_imported";
            this.tableData = "tableData importedTableData";
            this.btnStyle = "";
            this.checkBtnStyle = "";
          }
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_DELIVERY_INSTRUCTIONS_SEARCH, config)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // console.debug("getApiList(checkBoxFlag) Response", response); // 成功
                const list = [];
                if (jsonData.resCom.resComCode == "000") {
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  // if (this.totalCount > 0) {
                  // }
                  // 搬入計画リストを取得
                  jsonData.resIdv.deliveryInstructions.forEach((row) => {
                    if (this.$refs.form.validate()) {
                      list.push({
                        check: false,
                        outListSubSid: row.outListSubSid, // 出荷伝票明細SID
                        userClientSid: row.userClientSid, // user取引先SID
                        deliveryDatetime: row.deliveryDatetime, // 納入日時
                        billingSlipNo: row.outListNo, // 出荷伝票No.
                        replacementDiv: row.replacementDiv, // 差替区分
                        deliveryCd: row.deliveryCd, // 納入先CD
                        makerCd: row.clientCd, // メーカーコード
                        makerName: row.clientName, // メーカー取引先名称
                        makerPlant: row.clientPlantCd, // メーカー工場
                        deliveryTime:
                          row.deliveryTime.substr(0, 2) + ":" + row.deliveryTime.substr(2, 4), // 搬入時間
                        deliveryDestinationy: row.deliveryPlaceCd, // 搬入場所
                        deliveryPlan: row.planCd, // 計画コード
                        userItemSid: row.userItemCd, // ユーザー品番SID
                        userProductCd: row.userItemCd, // ユーザー品番
                        userProductCodeName: row.userName, // ユーザー品名
                        productAccessoryCd: row.userItemSubCd, // 付帯品番
                        instNum: String(commonUtil.formatToCurrency(Number(row.stock))), // 指示数
                        exiting: String(commonUtil.formatToCurrency(Number(row.issueStock))), // 出庫実績数
                        // stowage: row.stowageStock, // 積付実績数
                        // loading: row.loadingStock, // 積込実績数
                        stowage: this.cargosValue,
                        loading: this.cargosValue,
                        shipping: String(commonUtil.formatToCurrency(Number(row.shippingStock))), // 出荷実績数
                        dataIssueDate: row.dataCreateDateTime, // データ発行日
                        deliveryPrintDate: row.deliveryPrintDate, // 納品書
                        actualTicketPrintDate: row.actualTicketPrintDate, // 現品票
                        transactionId: row.transactionId, // トランザクションid
                        autoNo2: row.autoNo2, // 自動連番2
                        updateDatetime: row.updateDatetime, // 更新日時
                        makerClientSid: row.makerClientSid,
                      });
                    }

                    if (!checkBoxFlag) {
                      // 検索ボタン以外で検索処理が走った場合、元々チェックしていたレコードにチェックをいれる
                      for (var i = 0; i < list.length; i++) {
                        for (var j = 0; j < this.listCheckbox.length; j++) {
                          if (list[i].outListSubSid == this.listCheckbox[j].outListSubSid) {
                            list[i].check = true;
                          }
                        }
                      }
                    } else {
                      // 再検索前のチェックした項目をリセットする
                      this.isAllSelected = false;
                    }
                  });
                  this.searchDiv = this.importStatus;
                  this.inputList = list;
                  if (0 < list.length) {
                    this.inputListFlag = true;
                  }
                  resolve(response);
                } else {
                  // 処理結果コードが"000"（Success・Retry）以外の場合の処理です。
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  // OK NGボタン
                  this.infoDialog.firstPageFlag = true;
                  this.norClass = background;
                  this.notImportedClass = background;
                  this.importedClass = background;
                  this.tableData = "tableData";
                  // タスクを終了します（失敗）。
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;

                this.norClass = background;
                this.notImportedClass = background;
                this.importedClass = background;
                this.tableData = "tableData";
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item) {
      if (!item.check) {
        // チェックを入れた場合
        this.listCheckbox.push(item);
      } else {
        // チェックを外した場合
        this.listCheckbox.splice(this.listCheckbox.indexOf(item), 1);
      }
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      // this.defaultData = Object.assign({}, this.$data);
      this.getNumberBiz(sessionStorage.getItem("sales_office_sid"));
      this.dataIssueDateFrom = "";
      this.dataIssueDateFromCal = "";
      this.dataIssueDateTo = "";
      this.dataIssueDateToCal = "";
      if (this.$route.params.transitionFlg == true) {
        if (this.$route.params.linkKbn) {
          //異常画面のリンクボタン押下時
          this.shipSlipNo = this.$route.params.searchshipSlipNo;
          this.deliveryDate = this.$route.params.searchdeliveryDate;
          this.deliveryDateCal = this.$route.params.searchdeliveryDateCal;
          this.checkFlg1 = true;
          this.destinationyPlant = this.$route.params.searchdestinationyPlant;
          this.destinationyPlantList = this.$route.params.searchDestinationyPlantList;
        } else {
          this.checkFlg1 = true;
          this.userMakerSected = this.$route.params.searchuserMakerSected;
          this.importStatus = this.$route.params.searchimportStatus;
          this.userSuppliersSelected = this.$route.params.searchuserSuppliersSelected;

          this.selectHintClient = this.$route.params.searchselectHintClient;
          this.supplierList = this.$route.params.searchsupplierList;
          this.supplierList = this.$route.params.searchSuppliersList;
          this.clientSelected = this.$route.params.searchclientSelected;

          this.destinationyPlant = this.$route.params.searchdestinationyPlant;
          this.destinationyPlantList = this.$route.params.searchDestinationyPlantList;
          this.headerDeliveryDestinationy = this.$route.params.searchheaderDeliveryDestinationy;
          this.headerDeliveryPlan = this.$route.params.searchheaderDeliveryPlan;
          this.deliveryDate = this.$route.params.searchdeliveryDate;
          this.deliveryDateCal = this.$route.params.searchdeliveryDateCal;
          this.deliveryTimeFrom = this.$route.params.searchdeliveryTimeFrom;
          this.deliveryTimeTo = this.$route.params.searchdeliveryTimeTo;
          this.shipSlipNo = this.$route.params.searchshipSlipNo;
          this.dataIssueDateFrom = this.$route.params.searchdataIssueDateFrom;
          this.dataIssueDateFromCal = this.$route.params.searchdataIssueDateFromCal;
          this.dataIssueDateTo = this.$route.params.searchdataIssueDateTo;
          this.dataIssueDateToCal = this.$route.params.searchdataIssueDateToCal;

          this.productHint = this.$route.params.searchproductHint;
          this.userProductCdList = this.$route.params.searchuserProductCdList;
          this.userProductCd = this.$route.params.searchuserProductCd;
          this.issuedDiff = this.$route.params.searchIssuedDiff;
          this.deliveryPrintDate = this.$route.params.searchDeliveryPrintDate;
          this.actualTicketPrintDate = this.$route.params.searchActualTicketPrintDate;
          this.deliveryDatetime = this.$route.params.searchdeliveryDatetime;
          this.autoUpdateFlag = this.$route.params.searchautoUpdateFlag;
          this.autoUpdateIntervalMin = this.$route.params.searchautoUpdateIntervalMin;
          // item.makerPlant = this.$route.params.searchMakerPlant;
          // item.instNum = this.$route.params.searchInstNum;
          // item.deliveryTime = this.$route.params.searchDeliveryTime;
          // item.productAccessoryCd = this.$route.params.searchInstNum;
          this.userProductCd = this.$route.params.searchuserProductSid;
          this.inputListFlag = this.$route.params.searchInputListFlag;
          this.itemsPerPageBtn = this.$route.params.searchItemsPerPageBtn;
          this.btnid = this.$route.params.btnid;
        }
      }
      if (this.deliveryDate == "") {
        this.deliveryDate = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      }

      if (!this.suppliersSelected) {
        this.inputList = [];
      }

      if (this.autoUpdateFlag == "on") {
        this.autoUpdate();
        if (!this.autoUpdateIntervalMin == "") {
          this.autoUpdate();
        }
      }
      const codeList = this.getMstCode();
      Promise.all([codeList])
        .then(() => {
          // 画面の初期値を設定します。
          this.getCustomInfo();
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
      this.$watch(
        () => this.userMakerSected,
        (newVal) => {
          this.chengeUserMakerSected(newVal);
        }
      );
    },
    /**
     * 搬入計画登録画面を開きます。
     */
    // 新規登録ボタン押下
    initRegist() {
      let searchSuppliersList =
        this.clientSelected == "" || this.clientSelected == null
          ? []
          : new Array(...this.supplierList);
      let searchuserProductCdList =
        this.userProductCd == "" || this.userProductCd == null
          ? []
          : new Array(...this.userProductCdList);

      let searchDestinationyPlantList = this.destinationyPlantList;

      this.$router.push({
        name: appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_PLAN_ADD,

        params: {
          officeSid: this.user,
          searchuserMakerSected: this.userMakerSected,
          searchimportStatus: this.importStatus,
          searchuserSuppliersSelected: this.userSuppliersSelected,

          searchsupplierList: this.supplierList,
          searchMakerClientCd: this.clientCd,
          searchselectHintClient: this.selectHintClient,
          searchclientSelected: this.clientSelected,
          searchdestinationyPlant: this.destinationyPlant,
          searchheaderDeliveryDestinationy: this.headerDeliveryDestinationy,
          searchheaderDeliveryPlan: this.headerDeliveryPlan,
          searchdeliveryDate: this.deliveryDate,
          searchdeliveryDateCal: this.deliveryDateCal,
          searchdeliveryTimeFrom: this.deliveryTimeFrom,
          searchdeliveryTimeTo: this.deliveryTimeTo,
          searchshipSlipNo: this.shipSlipNo,
          searchdataIssueDateFrom: this.dataIssueDateFrom,
          searchdataIssueDateFromCal: this.dataIssueDateFromCal,
          searchdataIssueDateTo: this.dataIssueDateTo,
          searchdataIssueDateToCal: this.dataIssueDateToCal,
          searchuserItemCd: this.userItemCd,
          searchproductHint: this.productHint,
          searchuserProductCd: this.userProductCd,
          searchuserProductSid: this.userProductCd,
          searchautoUpdateIntervalMin: this.autoUpdateIntervalMin,
          searchdeliveryDatetime: this.deliveryDatetime,
          searchItemsPerPageBtn: this.itemsPerPageBtn,
          totalCount: this.anomalyNumber,
          searchautoUpdateFlag: this.autoUpdateFlag,
          searchInputListFlag: this.inputListFlag,
          searchIssuedDiff: this.issuedDiff,
          searchDeliveryPrintDate: this.deliveryPrintDate,
          searchActualTicketPrintDate: this.actualTicketPrintDate,
          searchSuppliersList: searchSuppliersList,
          searchuserProductCdList: searchuserProductCdList,
          searchDestinationyPlantList: searchDestinationyPlantList,
          btnid: this.btnid,
        },
      });
    },
    openUpdate(item) {
      let searchSuppliersList =
        this.clientSelected == "" || this.clientSelected == null
          ? []
          : new Array(...this.supplierList);
      let searchuserProductCdList =
        this.userProductCd == "" || this.userProductCd == null
          ? []
          : new Array(...this.userProductCdList);
      let searchDestinationyPlantList = this.destinationyPlantList;

      let alignmentCheck = this.userMakerSected == "01" ? true : false;

      this.$router.push({
        name: appConfig.MENU_ID.P_SUP_DELIVERY_ORDER_PLAN_UPDATE,

        params: {
          officeSid: this.user,
          searchuserMakerSected: this.userMakerSected,
          searchimportStatus: this.importStatus,
          searchuserSuppliersSelected: this.userSuppliersSelected,
          searchsupplierList: this.supplierList,
          searchMakerClientCd: this.clientCd,
          searchselectHintClient: this.selectHintClient,
          searchclientSelected: this.clientSelected,
          searchdestinationyPlant: this.destinationyPlant,
          searchheaderDeliveryDestinationyRow: item.deliveryDestinationy,
          searchheaderDeliveryDestinationy: this.headerDeliveryDestinationy,
          searchheaderDeliveryPlanRow: item.deliveryPlan,
          replacementDiv: item.replacementDiv,
          searchheaderDeliveryPlan: this.headerDeliveryPlan,
          searchdeliveryDate: this.headerDeliveryDate,
          searchdeliveryDateCal: this.deliveryDateCal,
          searchdeliveryTimeFrom: this.deliveryTimeFrom,
          searchdeliveryTimeTo: this.deliveryTimeTo,
          searchshipSlipNoRow: item.billingSlipNo,
          searchshipSlipNo: this.shipSlipNo,
          searchdataIssueDateFrom: this.dataIssueDateFrom,
          searchdataIssueDateFromCal: this.dataIssueDateFromCal,
          searchdataIssueDateTo: this.dataIssueDateTo,
          searchdataIssueDateToCal: this.dataIssueDateToCal,
          searchuserItemCd: this.userItemCd,
          searchproductHint: this.productHint,
          searchuserProductCd: item.userProductCd,
          searchuserProductSid: this.userProductCd,
          searchautoUpdateIntervalMin: this.autoUpdateIntervalMin,
          searchdeliveryDatetime: this.deliveryDatetime,
          searchItemsPerPageBtn: this.itemsPerPageBtn,
          totalCount: this.anomalyNumber,
          searchautoUpdateFlag: this.autoUpdateFlag,
          searchInputListFlag: this.inputListFlag,
          searchSuppliersList: searchSuppliersList,
          searchuserProductCdList: searchuserProductCdList,
          searchDestinationyPlantList: searchDestinationyPlantList,
          alignmentCheck: alignmentCheck,
          userClientSid: item.userClientSid,
          searchIssuedDiff: this.issuedDiff,
          searchDeliveryPrintDate: this.deliveryPrintDate,
          searchActualTicketPrintDate: this.actualTicketPrintDate,
          searchMakerPlant: item.makerPlant,
          searchDeliveryTime: item.deliveryTime,
          searchProductAccessoryCd: item.productAccessoryCd,
          searchInstNum: item.instNum,
          btnid: this.btnid,
        },
      });
    },

    /**
     * 復元ボタン押下時の処理
     */
    reImport() {
      let check =
        this.inputList.filter((element) => {
          return element.check === false || element.check === void 0;
        }).length === this.inputList.length;

      if (check == true) {
        this.errorFlg = false;
        this.infoDialog.firstPageFlag = true;
        this.updateDialogMessage = true;
        this.infoDialog.outsideClickNotCloseFlg = false;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      } else {
        this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
          i18n.tc("btn.btn_restoration"),
        ]);
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.reImportOkClick;
      }
    },
    /**
     * 再取込（メッセージだけ）
     */
    reImportOkClick() {
      this.loadingCounter = 1;
      // 初期表示の設定
      let deleteArray = [];

      this.deleteList = this.inputList.filter((element) => {
        return element.check === true;
      });
      for (let i = 0; i < this.deleteList.length; i++) {
        let item = this.deleteList[i];
        deleteArray.push({
          outListSubSid: item.outListSubSid,
          userClientSid: item.userClientSid,
          replacementDiv: item.replacementDiv,
          updateDatetime: item.updateDatetime,
          outListNo: item.billingSlipNo,
          makerClientSid: item.makerClientSid,
          updateKbn: "02",
        });
      }
      const config = this.$httpClient.createRequestBodyConfig();
      config.reqIdv.ibdiDtl = deleteArray;
      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_001;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_DELIVERY_DELETE, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-SUP-001_002_E");
              this.infoDialog.title = "結果";
              this.simpleDialog = true;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              this.inputList = this.inputList.filter((element) => {
                return element.check === false;
              });
              this.totalCount = this.totalCount - deleteArray.length;
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            console.error("GetParameter getUserBiz Err", ex);
            this.infoDialog.message = messsageUtil.getMessage("A-999-999_001_E");
            this.infoDialog.isOpen = true;
            this.infoDialog.title = appConfig.DIALOG.title;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(messsageUtil.getMessage("A-999-999_001_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
      // // 初期表示の設定
      // Object.assign(this.$data, this.defaultData);
      // // 画面遷移後の場合
      // if (this.transitionFlg) {
      //   this.suppliersSelected = "";
      //   // 遷移フラグ
      //   this.transitionFlg = false;
      // }
      // // 変更検知用の設定
      // this.dataIssueDateFromCal = "";
      // this.dataIssueDateToCal = "";
      // // バリデーションチェックをリセット
      // this.$refs.form.resetValidation();
      // this.infoDialog.message = messsageUtil.getMessage("P-SUP-001_001_E");
      // this.infoDialog.title = appConfig.DIALOG.title;
      // this.infoDialog.isOpen = true;
      // this.simpleDialog = true;
      // this.infoDialog.firstPageFlag = true;
      // this.loadingCounter = 0;
    },

    /**
     * 出力形式区分リストセット処理
     */
    setOutputFormatDiv() {
      for (var i = 0; i < this.dataFormatList.length; i++) {
        if (this.dataFormatList[i].value == this.dataFormatSelected) {
          return this.dataFormatList[i].name;
        }
      }
    },

    //データ出力ボタン押下時
    getOutPut() {
      var now = new Date();
      this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
      this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);
      // 現在時刻を表示

      if (this.$refs.form.validate()) {
        this.dataOutPutDialog = true;
      }
    },

    // データ出力
    outPutClick() {
      if (this.$refs.numIssueVali.validate()) {
        //未取込で搬入計画一覧(CSV)以外が選択されている場合はエラー
        if (this.btnid == "notImported" && this.dataFormatSelected != "01") {
          this.backFlg = false;
          this.updateDialogMessage = true;
          this.infoDialog.firstPageFlag = true;
          this.infoDialog.outsideClickNotCloseFlg = false;
          this.backMessage = messsageUtil.getMessage("P-SUP-001_003_E");
          return;
        }

        // 全選択を初期化
        this.isAllSelected = false;
        this.loadingCounter = 1;
        const config = this.$httpClient.createRequestBodyConfig();
        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_001;

        if (this.btnid == "notImported") {
          let notImpoetedList = this.inputList.filter((element) => {
            return element.check === true;
          });
          let notImpoetedArray = [];
          for (let i = 0; i < notImpoetedList.length; i++) {
            let item = notImpoetedList[i];
            notImpoetedArray.push({
              transactionId: item.transactionId, // トランザクションid
              autoNo2: item.autoNo2, // 自動連番2
            });
          }
          // トランザクションIDリスト
          config.reqIdv.transactionIdList = notImpoetedArray;
        } else {
          // 出荷伝票明細SIDリスト
          config.reqIdv.outListSubSidList = this.inputList
            .filter((element) => element.check === true)
            .map((val) => val.outListSubSid);
        }
        // データ出力区分
        config.reqIdv.dataFormatDiv = this.dataFormatSelected;
        // 取込状態
        if (this.btnid == "nor") {
          config.reqIdv.capturingState = "01";
        } else if (this.btnid == "notImported") {
          config.reqIdv.capturingState = "02";
        } else if (this.btnid == "imported") {
          config.reqIdv.capturingState = "03";
        }
        if (this.dataFormatSelected != "03") {
          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(appConfig.API_URL.BIZ_DELIVERY_DOWNLOAD, config, appConfig.APP_CONFIG)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 正常時
                if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                  window.location.href = jsonData.resIdv.downloadUrl;
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  // OK NGボタン
                  this.infoDialog.firstPageFlag = true;
                  reject(jsonData.resCom.resComMessage);
                }
              })
              .catch((ex) => {
                console.error("GetParameter getUserBiz Err", ex);
                this.infoDialog.message = messsageUtil.getMessage("A-999-999_001_E");
                this.infoDialog.isOpen = true;
                this.infoDialog.title = appConfig.DIALOG.title;
                // OK NGボタン
                this.infoDialog.firstPageFlag = true;
                reject(messsageUtil.getMessage("A-999-999_001_E"));
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
                this.dataOutPutDialog = false;
                // 全ての選択を解除
                this.listCheckbox = [];
                this.listCheckbox = false;
                for (var j = 0; j < this.inputList.length; j++) {
                  this.inputList[j].check = false;
                }
                this.isAllSelected = false;
                this.getApiList(true);
              });
          });
        } else {
          const config = this.$httpClient.createRequestBodyConfig();
          config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_001;
          // P_SUP_001
          // ローディング画面表示ON
          this.loadingCounter = 1;

          //APIに渡す値をconfig.paramsにセット==================================//
          // 取引先
          if (this.userSuppliersSelected) {
            config.reqIdv.clientSid = this.userSuppliersSelected;
          }

          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(
                appConfig.API_URL.BIZ_DELIVERYSTOCKTRANSITION_EXCLUSION,
                config,
                appConfig.APP_CONFIG
              )
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                if (jsonData.resCom.resComCode != "000") {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  // ローディング画面表示OFF
                  this.loadingCounter = 0;
                } else {
                  //APIに渡す値をconfig.paramsにセット==================================//
                  const config2 = this.$httpClient.createRequestBodyConfig();
                  config2.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_001;
                  // 伝票No
                  config2.reqIdv.outListSubSidList = this.inputList
                    .filter((element) => element.check === true)
                    .map((val) => val.outListSubSid);
                  // データ出力区分
                  config2.reqIdv.dataFormatDiv = this.dataFormatSelected;
                  // リクエストIDを取得
                  this.requestId = config2.reqCom.reqComRequestId;
                  return postParameter
                    .postMstQueInfo(config2)
                    .then(() => {
                      // キュー状況モダールを表示
                      this.openDialogQueInfoStatus();
                    })
                    .catch((ex) => {
                      this.infoDialog.message = ex;
                      this.infoDialog.title = appConfig.DIALOG.title;
                      this.infoDialog.isOpen = true;
                      this.infoDialog.firstPageFlag = true;
                      // ローディング画面表示OFF
                      this.loadingCounter = 0;
                      this.exclusionEnd();
                    })
                    .finally(() => {});
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                this.dataOutPutDialog = false;
              });
          });
        }
      }
    },

    // 在庫情報出力画面：ポップアップ閉じる
    closeDetailsInfo() {
      this.dataOutPutDialog = false;
    },

    /**
     * 削除ボタン押下時の処理（メッセージ)
     */
    checkDelete() {
      let error =
        this.inputList.filter((element) => {
          return element.check === false || element.check === void 0;
        }).length === this.inputList.length;
      // this.deleteList = this.inputList.filter((element) => {
      //   return element.check === true;
      // });

      if (error == true) {
        this.errorFlg = false;
        this.infoDialog.firstPageFlag = true;
        this.updateDialogMessage = true;
        this.infoDialog.outsideClickNotCloseFlg = false;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      } else {
        this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
          i18n.tc("btn.btn_delete"),
        ]);
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.checkDeleteOkClick;
      }
    },
    /**
     * 削除ボタン押下処理
     */
    checkDeleteOkClick() {
      this.loadingCounter = 1;
      // 初期表示の設定
      let deleteArray = [];

      this.deleteList = this.inputList.filter((element) => {
        return element.check === true;
      });
      for (let i = 0; i < this.deleteList.length; i++) {
        let item = this.deleteList[i];
        deleteArray.push({
          outListSubSid: item.outListSubSid,
          userClientSid: item.userClientSid,
          replacementDiv: item.replacementDiv,
          updateDatetime: item.updateDatetime,
          outListNo: item.billingSlipNo,
          makerClientSid: item.makerClientSid,
          updateKbn: "01",
        });
      }

      const config = this.$httpClient.createRequestBodyConfig();
      config.reqIdv.ibdiDtl = deleteArray;
      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_001;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_DELIVERY_DELETE, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-MST-014_001_C");
              this.infoDialog.title = "結果";
              this.simpleDialog = true;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              this.inputList = this.inputList.filter((element) => {
                return element.check === false;
              });
              this.totalCount = this.totalCount - deleteArray.length;
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            console.error("GetParameter getUserBiz Err", ex);
            this.infoDialog.message = messsageUtil.getMessage("A-999-999_001_E");
            this.infoDialog.isOpen = true;
            this.infoDialog.title = appConfig.DIALOG.title;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(messsageUtil.getMessage("A-999-999_001_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },

    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      // Object.assign(this.$data, this.defaultData);
      this.$route.params.transitionFlg = false;
      this.inputListFlag = false;
      let autoUpdateFlag = this.autoUpdateFlag;
      this.autoUpdateFlag = autoUpdateFlag;
      let autoUpdateInterval = this.autoUpdateIntervalMin;
      this.autoUpdateIntervalMin = autoUpdateInterval;
      this.userSuppliersSelected = "";
      this.userMakerSected = "01";
      this.clientSelected = "";
      this.supplierList = [];
      this.destinationyPlantList = [];
      this.destinationyPlant = "";
      this.headerDeliveryDestinationy = "";
      this.headerDeliveryPlan = "";
      this.deliveryDate = dateTimeHelper.convertJST().substr(0, 10);
      this.deliveryDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
      this.deliveryTimeFrom = "";
      this.deliveryTimeTo = "";
      this.shipSlipNo = "";
      this.importStatus = "01";
      this.dataIssueDateFrom = "";
      this.dataIssueDateFromCal = "";
      this.dataIssueDateTo = "";
      this.dataIssueDateToCal = "";
      this.userProductCd = "";
      this.userProductCdList = [];
      this.totalCount = "0";
      this.itemsPerPageBtn = "500";
      const background = this.defaultbtn;
      this.norClass = background;
      this.notImportedClass = background;
      this.importedClass = background;
      this.tableData = "tableData";
      this.issuedDiff = "";
      this.deliveryPrintDate = "";
      this.actualTicketPrintDate = "";

      // 画面遷移後の場合
      if (this.checkFlg) {
        // 遷移フラグ
        this.checkFlg = false;
      }
      // 初期化

      this.init(true);
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    /**
     * 検索ボタンIDから実行（自動更新用）
     */
    autoUpdateId() {
      //clearInterval(interval4times);
      document.getElementById("get-search").click();
      console.log("autoUpdateId");
    },
    getNumberBiz(officeSid) {
      // 異常・緊急件数
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = officeSid;
      // console.debug("getParameter getUserBiz Url", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_GET_NUMBER, config)
          .then((response) => {
            // console.debug("getParameter getUserBiz Response", response, jsonData);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.anomalyNumber = jsonData.resCom.resComCount;
              if (0 < this.anomalyNumber) {
                this.emergencyFlag = "on";
              }
              resolve(this.anomalyNumber);
            } else {
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            console.error("GetParameter getUserBiz Err", ex);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          });
      });
    },
    /**
     * 異常通知押下時の処理
     */
    emergencyNotice() {
      let searchSuppliersList =
        this.clientSelected == "" || this.clientSelected == null
          ? []
          : new Array(...this.supplierList);
      let searchuserProductCdList =
        this.userProductCd == "" || this.userProductCd == null
          ? []
          : new Array(...this.userProductCdList);

      let searchDestinationyPlantList = this.destinationyPlantList;

      this.$router.push({
        name: appConfig.SCREEN_ID.P_SUP_004,
        params: {
          searchuserMakerSected: this.userMakerSected,
          searchimportStatus: this.importStatus,
          searchuserSuppliersSelected: this.userSuppliersSelected,

          searchsupplierList: this.supplierList,
          searchMakerClientCd: this.clientCd,
          searchselectHintClient: this.selectHintClient,
          searchclientSelected: this.clientSelected,
          searchdestinationyPlant: this.destinationyPlant,
          searchheaderDeliveryDestinationy: this.headerDeliveryDestinationy,
          searchheaderDeliveryPlan: this.headerDeliveryPlan,
          searchdeliveryDate: this.deliveryDate,
          searchdeliveryDateCal: this.deliveryDateCal,
          searchdeliveryTimeFrom: this.deliveryTimeFrom,
          searchdeliveryTimeTo: this.deliveryTimeTo,
          searchshipSlipNo: this.shipSlipNo,
          searchdataIssueDateFrom: this.dataIssueDateFrom,
          searchdataIssueDateFromCal: this.dataIssueDateFromCal,
          searchdataIssueDateTo: this.dataIssueDateTo,
          searchdataIssueDateToCal: this.dataIssueDateToCal,
          searchuserItemCd: this.userItemCd,
          searchproductHint: this.productHint,
          searchuserProductCd: this.userProductCd,
          searchautoUpdateIntervalMin: this.autoUpdateIntervalMin,
          searchdeliveryDatetime: this.deliveryDatetime,
          searchItemsPerPageBtn: this.itemsPerPageBtn,
          totalCount: this.anomalyNumber,
          searchautoUpdateFlag: this.autoUpdateFlag,
          searchInputListFlag: this.inputListFlag,
          searchIssuedDiff: this.issuedDiff,
          searchDeliveryPrintDate: this.deliveryPrintDate,
          searchActualTicketPrintDate: this.actualTicketPrintDate,
          searchSuppliersList: searchSuppliersList,
          searchuserProductCdList: searchuserProductCdList,
          searchDestinationyPlantList: searchDestinationyPlantList,
          btnid: this.btnid,
        },
      });
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 納入日を－１日します。
     */
    prevDate() {
      this.deliveryDateCal = commonFunction.addDate(this.deliveryDateCal, "");
    },
    /**
     * 納入日を＋１日します。
     */
    nextDate() {
      this.deliveryDateCal = commonFunction.addDate(this.deliveryDateCal, "add");
    },
    /**
     * データ発行日Fromを－１日します。
     */
    prevFromDate() {
      this.dataIssueDateFromCal = commonFunction.addDate(this.dataIssueDateFromCal, "");
    },

    /**
     * データ発行日Fromを＋１日します。
     */
    nextFromDate() {
      this.dataIssueDateFromCal = commonFunction.addDate(this.dataIssueDateFromCal, "add");
    },
    /**
     * データ発行日Toを－１日します。
     */
    prevToDate() {
      this.dataIssueDateToCal = commonFunction.addDate(this.dataIssueDateToCal, "");
    },

    /**
     * データ発行日Toを＋１日します。
     */
    nextToDate() {
      this.dataIssueDateToCal = commonFunction.addDate(this.dataIssueDateToCal, "add");
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(納入日)
     */
    changeDateDelivery(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.deliveryDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.deliveryDateCal = null;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.dataIssueDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.dataIssueDateFromCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.dataIssueDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.dataIssueDateToCal = null;
      }
    },
    convertStringToNumber(val) {
      if (typeof val !== "string" || val === "") return false;
      const num = Number(val);
      // 数値に変換できない場合はNaNが返る
      return isNaN(num) ? false : num;
    },
    /**
     * チェックボックス取得処理
     */
    onInputCheck(item) {
      if (item.check) {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].value = true;
        }
      } else {
        for (let i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = false;
          this.inputList[i].value = false;
        }
      }
    },
    /**
     * ユーザー取引先コードリストボックス取得処理
     */
    setSrhUserSupplier() {
      for (var i = 0; i < this.userSupplierList.length; i++) {
        if (this.userSupplierList[i].value == this.userSuppliersSelected) {
          return this.userSupplierList[i].name;
        }
      }
    },
    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      if (!this.supplierList) return;
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.clientSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    /**
     * ユーザー品番コードリストボックス取得処理
     */
    setSrhUserProductCd() {
      if (!this.userProductCdList) return;
      for (var i = 0; i < this.userProductCdList.length; i++) {
        if (this.userProductCdList[i].value == this.userProductCd) {
          return this.userProductCdList[i].name;
        }
      }
    },
    /**
     * 納入先工場コードリストボックス取得処理
     */
    setDestinationyPlant() {
      if (!this.destinationyPlantList) return;
      for (var i = 0; i < this.destinationyPlantList.length; i++) {
        if (this.destinationyPlantList[i].value == this.destinationyPlant) {
          return this.destinationyPlantList[i].name;
        }
      }
    },
    /**
     * 差異有りリストボックス取得処理
     */
    setIssuedDiff() {
      for (var i = 0; i < this.issuedDiffList.length; i++) {
        if (this.issuedDiffList[i].value == this.issuedDiff) {
          return this.issuedDiffList[i].name;
        }
      }
    },

    /**
     * 納品書リストボックス取得処理
     */
    setDeliveryPrintDate() {
      for (var i = 0; i < this.deliveryPrintDateList.length; i++) {
        if (this.deliveryPrintDateList[i].value == this.deliveryPrintDate) {
          return this.deliveryPrintDateList[i].name;
        }
      }
    },

    /**
     * 現品票リストボックス取得処理
     */
    setActualTicketPrintDate() {
      for (var i = 0; i < this.actualTicketPrintDateList.length; i++) {
        if (this.actualTicketPrintDateList[i].value == this.actualTicketPrintDate) {
          return this.actualTicketPrintDateList[i].name;
        }
      }
    },

    /**
     * キュー状況ダイアログ表示処理
     */
    openDialogQueInfoStatus() {
      // キュー状況ダイアログデータ
      this.queInfoList = [
        {
          queInfoMessage: i18n.tc("label.lbl_messageStart"),
        },
      ];
      this.dialogQueInfoStatus = true;
      this.queInfoStatus();
    },

    // キューの進捗表示
    async queInfoStatus() {
      // 既存処理、終了
      if (this.timeId) {
        clearInterval(this.timeId);
      }
      this.timeId = setInterval(this.reqQueInfoStatusResult, 5000);
    },

    /**
     * キュー状況API
     */
    reqQueInfoStatusResult() {
      this.resultList = [];
      return new Promise((resolve, reject) => {
        getParameter
          .getMstQueInfo({ requestId: this.requestId })
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (jsonData.resIdv.queInfo.completeFlg == "1") {
                clearInterval(this.timeId);
                if (jsonData.resIdv.queInfo.url == null || jsonData.resIdv.queInfo.url == "") {
                  this.queInfoList.push({
                    queInfoMessage: messsageUtil.getMessage("A-999-999_025_E", ["現品票の出力"]),
                  });
                } else {
                  window.location.href = jsonData.resIdv.queInfo.url;
                }
                this.queInfoList.push({
                  queInfoMessage: i18n.tc("label.lbl_messageFinished"),
                });
                this.exclusionEnd();
                this.loadingCounter = 0;
              }
            } else {
              // エラー時
              return Promise.reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            clearInterval(this.timeId);
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            this.loadingCounter = 0;
            reject(resolve);
          });
      });
    },

    // 排他制御終了
    exclusionEnd() {
      if (this.$refs.form.validate()) {
        const config = this.$httpClient.createRequestBodyConfig();
        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_001;
        // ローディング画面表示ON
        this.loadingCounter = 1;

        //APIに渡す値をconfig.paramsにセット==================================//
        // 取引先
        if (this.userSuppliersSelected) {
          config.reqIdv.clientSid = this.userSuppliersSelected;
        }

        // リクエストIDを取得
        config.reqIdv.requestId = this.requestId;
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(
              appConfig.API_URL.BIZ_DELIVERYSTOCKTRANSITION_EXCLUSION_END,
              config,
              appConfig.APP_CONFIG
            )
            .then(() => {})
            .catch((ex) => {
              console.error("GetParameter getUserBiz Err", ex);
              this.infoDialog.message = messsageUtil.getMessage("A-999-999_001_E");
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject(messsageUtil.getMessage("A-999-999_001_E"));
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
              // 検索処理を実行
              this.getApiList(true);
            });
        });
      }
    },
    /**
     * 再取込ボタン押下時の処理（メッセージ)
     */
    checkReUpload() {
      let error =
        this.inputList.filter((element) => {
          return element.check === false || element.check === void 0;
        }).length === this.inputList.length;

      if (error == true) {
        this.errorFlg = false;
        this.infoDialog.firstPageFlag = true;
        this.updateDialogMessage = true;
        this.infoDialog.outsideClickNotCloseFlg = false;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      } else {
        this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
          i18n.tc("btn.btn_re_upload"),
        ]);
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.checkReUploadOkClick;
      }
    },
    /**
     * 再取込ボタン押下処理
     */
    checkReUploadOkClick() {
      this.loadingCounter = 1;

      let reUploadArray = [];
      let reUploadList = [];
      reUploadList = this.inputList.filter((element) => {
        return element.check === true;
      });
      for (let i = 0; i < reUploadList.length; i++) {
        let item = reUploadList[i];
        reUploadArray.push({
          transactionId: item.transactionId, // トランザクションid
          autoNo2: item.autoNo2, // 自動連番2
        });
      }

      const config = this.$httpClient.createRequestBodyConfig();
      config.reqIdv.deliveryInstructions = reUploadArray;
      config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SUP_001;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_DELIVERY_INST_RE_UPLOAD, config, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.simpleDialog = true;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              this.getApiList(true);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.isOpen = true;
              this.infoDialog.title = appConfig.DIALOG.title;
              // OK NGボタン
              this.infoDialog.firstPageFlag = true;
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            console.error("checkReUploadOkClick Err", ex);
            this.infoDialog.message = messsageUtil.getMessage("A-999-999_001_E");
            this.infoDialog.isOpen = true;
            this.infoDialog.title = appConfig.DIALOG.title;
            // OK NGボタン
            this.infoDialog.firstPageFlag = true;
            reject(messsageUtil.getMessage("A-999-999_001_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },

  mounted() {
    this.init();
  },
  computed: {
    downloadFlg() {
      let flg = true;
      let count = this.inputList.filter((element) => {
        return element.check === true;
      }).length;
      if (count !== 0 && (this.btnid === "nor" || this.btnid === "notImported")) {
        flg = false;
      }
      return flg;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    searchClient(val) {
      if (val == null) {
        return;
      }
      if (this.checkFlg1) {
        this.checkFlg1 = false;
        return;
      }
      if (val ?? false) {
        // 取引先が選択されてない場合、処理しない
        if (
          this.userMakerSected == "01" &&
          (this.userSuppliersSelected == null || this.userSuppliersSelected == "")
        ) {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.selectHintClient) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得

          const fromList =
            this.userMakerSected == "02"
              ? getParameter.getRelatedBizPartialMatch(
                  appConfig.ISGETCLIENT.CLIENT,
                  dateTimeHelper.convertUTC(this.arrivalScheduleDate),
                  undefined,
                  undefined,
                  val,
                  sessionStorage.getItem("sales_office_sid")
                )
              : getParameter.getUserdBizPartialMatch(
                  this.userSuppliersSelected,
                  val,
                  this.officeSid
                );

          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.supplierList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.supplierList.length = 0;
          this.supplierList = [];
        }
      } else {
        this.supplierList.length = 0;
        this.supplierList = [];
        this.clientSelected = "";
      }
    },
    // ユーザー品番取得処理
    searchUserProductCd(val) {
      if (val ?? false) {
        // ユーザー品番が選択されてない場合、処理しない
        if (this.userSuppliersSelected == null || this.userSuppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          // 選択した会社コードを取得

          const fromList = getParameter.getUserItemsBizAddName(this.userSuppliersSelected, val);
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.userProductCdList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.userProductCdList = [];
          this.userProductCdList.length = 0;
        }
      } else {
        this.userProductCdList = [];
        this.userProductCdList.length = 0;
        this.userProductCd = "";
      }
    },

    //取込状態切り替え処理
    importStatus: function (newVal) {
      if (newVal == "01") {
        this.isDisabledFreeReason = false;
      } else if (newVal == "02") {
        // this.userMakerSected = "01";
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
      }
    },
    dialog(val) {
      val || this.close();
    },
    deliveryDateCal(val) {
      this.deliveryDate = this.formatDate(val);
    },
    dataIssueDateTo() {
      this.alertMessage = "";
    },
    dataIssueDateFromCal(val) {
      this.dataIssueDateFrom = this.formatDate(val);
    },
    dataIssueDateToCal(val) {
      this.dataIssueDateTo = this.formatDate(val);
    },
    deliveryTimeTo() {
      this.timeAlertMessage = "";
    },
    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false);
    },
    // ソート処理
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          this.chengeSortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.chengeAscdesc = items.sortDesc[0] ? " desc" : " asc";
          if (this.importStatus != "02") {
            this.sortItem = appConfig.P_SUP_001_SORT_T601[sortBy].replaceAll("{0}", ascdesc);
          } else {
            this.sortItem = appConfig.P_SUP_001_SORT_T600[sortBy].replaceAll("{0}", ascdesc);
          }

          this.getApiList(false);
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.autoUpdate ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 110px;
}

.download-btn {
  width: 10rem;
}

.serch-textbox-arrivalNo ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  width: 153px;
}
#listData.v-data-table ::v-deep .v-data-table__wrapper {
  max-height: 500px;
  height: 100%;
}
.textbox {
  width: 11.2rem;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}
.span1 {
  width: 4.3rem;
}
.span2 {
  width: 8rem;
}
.span3 {
  width: 8.7rem;
}
a {
  text-decoration: underline;
}
.v-btn.v-btn--has-bg.cargos_btn {
  text-transform: none;
  background: #dee6f3;
}
.cargos_btn ::v-deep .v-btn__content {
  color: #000 !important;
  font-weight: normal !important;
}
.v-btn.v-btn--has-bg.btn_search {
  width: 5.5rem;
}
.v-btn.v-btn--has-bg.btn_search_nor {
  background: #33ffff;
}

.v-btn.v-btn--has-bg.btn_search_notImported {
  background: #ffff66;
}
.v-btn.v-btn--has-bg.btn_search_imported {
  background: #ff6666;
}
#listData.norTableData ::v-deep th {
  background: #33ffff;
}
#listData.notImportedTableData ::v-deep th {
  background: #ffff66;
}
#listData.importedTableData ::v-deep th {
  background: #ff6666;
}
.date-style {
  width: 11rem;
}
#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 8px;
}

#QueInfoStatusData {
  &.v-data-table--fixed-header ::v-deep {
    tr:nth-child(odd) {
      background-color: #dde5f0;
      height: 2rem;
    }

    //テーブル項目部分
    // th::after {
    //   content: "*" !important;
    //   color: red;
    // }
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    //テーブルデータ部分
    td {
      font-size: large;
    }
  }
}
</style>
