<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_inventoryReservation" />

      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <!-- v-row 1 取引先 始まり -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                id="get-supplier"
                class="txt-single"
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                persistent-hint
                :rules="[rules.supplierRequired]"
                pa-3
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>
            <!-- v-row 1 取引先 終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- v-row 1 出荷予定日 カレンダー 始まり -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                id="cal"
                v-model="shipInsDate"
                :label="$t('label.lbl_shippingScheduleDate')"
                @change="changeDate"
                :rules="[rules.yyyymmddRequired]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="shipInsDateCal" @input="dateMenu = false"></v-date-picker>
              </v-menu>

              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>

              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- v-row 1 出荷予定日 カレンダー 終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- v-row 1 引当基準 始まり -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                id="get-reservation"
                v-model="reserveBasisSelected"
                :items="reserveBasisList"
                :label="$t('label.lbl_reserveBasis')"
                class="txt-single"
                :hint="setReserveBasis()"
                persistent-hint
                :rules="[rules.reservationRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- v-row 1 引当基準 終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- v-row 1 ピッキング 始まり -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                id="get-picking"
                v-model="pickingSelected"
                :items="pickingList"
                :label="$t('label.lbl_picking')"
                class="txt-single"
                :hint="setPicking()"
                persistent-hint
                :rules="[rules.pickingRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- v-row 1 ピッキング 終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- v-row 1 輸送会社名 始まり -->
            <div class="search-textbox">
              <v-text-field
                id="get-shipCampany"
                outlined
                dense
                v-model="truckNm"
                :label="$t('label.lbl_shippingCompanyName')"
                class="txt-single"
                readonly
                append-icon="mdi-close-circle"
                @click:append="append(), (truckNm = '')"
              />
            </div>
            <!-- v-row 1 輸送会社名 終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- v-row 1 輸送会社ボタン 始まり -->
            <div>
              <v-btn color="primary" class="other-btn" v-on:click="openTruck">
                {{ $t("btn.btn_addShpAdress") }}
              </v-btn>
            </div>
            <!-- v-row 1 輸送会社ボタン 終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- v-row 1 出荷先 始まり -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                id="get-destination"
                v-model="destinationSelected"
                :items="destinationSelectedList"
                item-text="displayText"
                item-value="value"
                :label="$t('label.lbl_destination')"
                class="txt-single"
                :hint="setStsArrival()"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="getSuppliersSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- v-row 1 出荷先 終わり -->
          </v-row>

          <v-row>
            <!-- v-row 2 品質区分 始まり -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="qualitySelected"
                :items="qualityList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- v-row 2 品質区分 終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- v-row 2 納品日 カレンダー 始まり -->
            <div class="date-style" style="float: left">
              <v-text-field
                outlined
                dense
                v-model="dateToDelivery"
                :label="$t('label.lbl_delDate')"
                @change="changeDeliveryDate"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
                :rules="[
                  rules.yyyymmdd,
                  rules.isFutureDate(
                    dateToDelivery,
                    shipInsDate,
                    $t('label.lbl_shippingScheduleDate')
                  ),
                ]"
              ></v-text-field>
            </div>
            <div class="calender-btn-area" style="float: left">
              <v-menu
                v-model="dateToDeliveryMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dateToDeliveryCal"
                  @input="dateToDeliveryMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDeliveryDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDeliveryDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- v-row 2 納品日 カレンダー 終わり -->

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
          </v-row>

          <!-- v-row 3 ボタン 始まり -->
          <v-row>
            <div class="search-row-exeBtn">
              <!-- v-row 2 引当ボタン -->
              <div class="btn-search-area" style="float: left">
                <v-btn color="primary" id="btn-search" class="get-btn" @click="search">{{
                  $t("btn.btn_reservation")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <!-- * -->

              <!-- v-row 2 引当確定ボタン -->
              <div class="btn-search-area" style="float: right">
                <v-btn
                  color="primary"
                  id="btn-search"
                  class="post-btn"
                  @click="viewConfirmDialog('btn_reservationConfirm')"
                  :disabled="isReservationConfirmBtnDisabled()"
                  >{{ $t("btn.btn_reservationConfirm") }}</v-btn
                >
              </div>
              <!-- * -->
            </div>
          </v-row>
          <!-- v-row 3 ボタン 終わり -->

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalSearch }}件</span>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inventoryList"
          disable-filtering
          disable-pagination
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="600px"
        >
          <!-- ロット -->
          <template v-slot:[`header.lot`]="{ header }">
            <span>
              {{ header.text }}<br />
              {{
                $t("label.lbl_dueDate") +
                "／" +
                $t("label.lbl_lotSubkey1") +
                "／" +
                $t("label.lbl_lotSubkey2")
              }}
            </span>
          </template>

          <!-- 出荷No -->
          <template v-slot:[`item.shipInsNumber`]="{ item }">
            <div class="listNo-style">{{ item.shipInsNumber }}</div>
          </template>
          <!-- 出荷先 -->
          <template v-slot:[`item.destination`]="{ item }">
            <div v-if="item.destination.length > 9">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 150px" v-bind="attrs" v-on="on">
                    {{ item.destination }}
                  </div>
                </template>
                <div>{{ item.destination }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.destination }}</div>
            </div>
          </template>
          <!-- * -->

          <!-- 品番・品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div v-if="item.productName && item.productName.length > 15">
              <div v-if="item.accessoryProductCd">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 150px" v-bind="attrs" v-on="on">
                      {{ item.productCnCd }}<br />
                      {{ item.accessoryProductCd }}<br />
                      {{ item.productName }}
                    </div>
                  </template>
                  <div>{{ item.productCnCd }}</div>
                  <div>{{ item.accessoryProductCd }}</div>
                  <div>{{ item.productName }}</div>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-overflow" style="max-width: 150px" v-bind="attrs" v-on="on">
                      {{ item.productCnCd }}<br />
                      {{ item.productName }}
                    </div>
                  </template>
                  <div>{{ item.productCnCd }}</div>
                  <div>{{ item.productName }}</div>
                </v-tooltip>
              </div>
            </div>
            <div v-else>
              <div v-if="item.accessoryProductCd">
                <div>
                  {{ item.productCnCd }}<br />
                  {{ item.accessoryProductCd }}<br />
                  {{ item.productName }}
                </div>
              </div>
              <div v-else>
                <div>
                  {{ item.productCnCd }}<br />
                  {{ item.productName }}
                </div>
              </div>
            </div>
          </template>
          <!-- * -->

          <!-- ロット -->
          <template v-slot:[`item.lot`]="{ item }">
            <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 250px" v-bind="attrs" v-on="on">
                    {{ item.lot }}<br />
                    {{ item.dueDate }}
                  </div>
                </template>
                <div>{{ item.lot }}<br />{{ item.dueDate }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.lot }}<br />
              {{ item.dueDate }}
            </div>
          </template>
          <!-- * -->

          <!-- 倉庫/ロケーション -->
          <template v-slot:[`item.location`]="{ item }">
            <div style="max-width: 150px">
              <div>{{ item.warehouse }}</div>
            </div>
            <div style="max-width: 150px">
              <div>{{ item.location }}</div>
            </div>
          </template>

          <!-- 担当者 -->
          <template v-slot:[`item.PIC`]="{ item, index }">
            <span>{{ item.PIC }}</span>
            <v-btn small id="checkItem" @click="openPIC(item, index)" text>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <!-- * -->

          <!-- 輸送会社 -->
          <template v-slot:[`item.shipCampany`]="{ item }">
            <span v-if="item.shipCampany == ''">{{ item.carrierCompName }}</span>
            <span v-else>{{ item.shipCampany }}</span>
          </template>

          <!-- 状況 -->
          <template v-slot:[`item.unreserveQtyResult`]="{ item }">
            <div :style="{ color: item.unreserveQtyResult === '引当可' ? 'black' : 'red' }">
              <div>{{ item.unreserveQtyResult }}</div>
            </div>
          </template>

          <!-- * -->
        </v-data-table>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :redMessage="infoDialog.redMessage"
        :homePageFlag="infoDialog.homePageFlag"
        :InvResFlg="infoDialog.InvResFlg"
        :resConFlg="infoDialog.resConFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        v-on:colsePopup="colsePopup"
        :okAction="infoDialog.okAction"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :isRequireOkActionWhenpressOkBtn="infoDialog.isRequireOkActionWhenpressOkBtn"
      />

      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :screenFlag="confirmDialog.screenFlag"
        :changeFlag="confirmDialog.changeFlag"
        :okBtnTxt="confirmDialog.okBtnTxt"
        :cancelBtnTxt="confirmDialog.cancelBtnTxt"
        :isAlert="confirmDialog.isAlert"
        :okBtnColor="confirmDialog.okBtnColor"
        :outsideClickNotCloseFlg="confirmDialog.outsideClickNotCloseFlg"
      />

      <!-- タイムアウトダイアログ -->
      <v-dialog v-model="timeoutDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>結果</v-card-title>
          <v-card-text class="pa-4">
            <p style="white-space: pre-wrap">{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="okClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- トラックダイアログ -->
      <v-dialog v-model="dialogShpAdress" :max-width="800">
        <Truck
          :isOpenDetailDialog.sync="dialogShpAdress"
          @click="saveTruck"
          :truckInfo="truckInfo"
          ref="truck"
        />
      </v-dialog>
      <!-- * -->

      <!-- ロケーションダイアログ -->
      <v-dialog v-model="dialogPIC" :max-width="800">
        <PICDialog
          :isOpenDetailDialog.sync="dialogPIC"
          v-on:setPIC="savePIC"
          v-on:closePopup="setPopup"
        />
      </v-dialog>
      <!-- * -->
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import PICDialog from "../../views/common/UserSelection";
import SimpleDialog from "@/components/SimpleDialog";
import Truck from "../../views/common/Truck";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.MENU_ID.P_STK_INVEN_ALLOC,
  components: {
    Loading,
    ConfirmDialog,
    SimpleDialog,
    sideMenu,
    NavBar,
    Truck, // トラックダイアログ
    PICDialog, // 担当者ダイアログ
  },
  mixins: [commonRules],
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    truckInfo: {
      suppliersSelected: "",
      truckInfo: "",
    },
    // 配送先情報画面ダイアログ
    dialogShpAdress: false, // 一覧index
    // 担当者ダイアログ
    dialogPIC: false,
    // ロケ仮置きデータ
    location: "",
    // 入荷予定日付
    shipInsDateCal: "",
    shipInsDate: "",
    // ピッキング
    pickingSelected: "",
    // 品質区分
    qualitySelected: "",
    qualityList: [],
    // 取引先
    suppliersSelected: "",
    // 引当基準
    reserveBasisSelected: "",
    // 出荷先
    destinationSelected: "",
    // 検索件数
    totalSearch: "0",
    // メニュー
    openMenu: null,
    // 出荷予定日付キャレンダー
    dateMenu: false,
    dateToDeliveryMenu: false,
    // 納品日
    dateToDelivery: "",
    // 納品日カレンダー
    dateToDeliveryCal: "",
    alertMessage: "",
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      homePageFlag: false,
      InvResFlg: false,
      resConFlg: false,
      firstPageFlag: false,
      okAction: () => {},
    },
    defaultInfoDialog: {
      isOpen: false,
      title: "",
      message: "",
      homePageFlag: false,
      InvResFlg: false,
      resConFlg: false,
      firstPageFlag: false,
      okAction: () => {},
    },
    // タイムアウトダイアログ
    timeoutDialogMessage: false,
    // タイムアウトダイアログメッセージ
    backMessage: "",
    initInventoryReservation: "",
    // 一覧
    inventoryList: [],
    // 選択した輸送会社
    shipCampanyinput: "",
    // 出荷先絞り込み一覧
    inventoryDList: [],
    // 引当確定リスト
    availableReserveList: [],
    // 子から受け取ったPICデータ
    selectPIC: "",
    // 子から受け取ったPICSidデータ
    selectPICSid: "",
    // 輸送会社名（画面表示用）
    truckNm: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    // 輸送会社名（登録用）
    truck: "",
    // 状況
    unreserveQtyResult: "",
    // 取引先Sidリスト
    supplierSidList: "",
    // シングルヘッダリスト
    singleHeaderItem: [],
    // トータルヘッダリスト
    totalHeaderItems: [],
    // 引当基準データ
    inventoryApiData: [],
    // ソート
    sortItem: "",
    sortOptions: {},
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "shipInsNumber",
        width: "130px",
        sortable: true,
        align: "left",
      },
      {
        text: i18n.tc("label.lbl_shippingScheduleDate"),
        value: "shipInsDate",
        width: "105px",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_delDate"),
        value: "dateToDelivery",
        width: "100px",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_destination"),
        value: "destination",
        width: "130px",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "130px",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "270px",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_shippedQuantity"),
        value: "shipQtyDisp",
        width: "100px",
        align: "right",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_warehouse") + "/" + i18n.tc("label.lbl_location"),
        value: "location",
        width: "130px",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_PIC"),
        value: "PIC",
        width: "100px",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_shippingCompanyName"),
        value: "shipCampany",
        width: "120px",
        align: "left",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_reserveQuantity"),
        value: "reserveQtyDisp",
        width: "80px",
        align: "right",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_unreserveQuantity"),
        value: "unreserveQtyDisp",
        width: "90px",
        align: "right",
        sortable: true,
      },
      {
        text: i18n.tc("label.lbl_Situatuion"),
        value: "unreserveQtyResult",
        width: "80px",
        align: "left",
        sortable: true,
      },
    ],
    destinationHeader: {
      text: i18n.tc("label.lbl_destination"),
      value: "destination",
      width: "136px",
      align: "left",
      sortable: false,
    },
    productHeader: {
      text: i18n.tc("label.lbl_productCnCd"),
      value: "productCnCd",
      width: "136px",
      align: "left",
    },
    // 取引先検索項目
    supplierList: [],
    // ピッキング検索項目
    pickingList: [],
    // 引当基準検索項目
    reserveBasisList: [],
    // 出荷先検索項目
    destinationSelectedList: [],
    // 選択行
    checkRow: -1,
    // ロケSID
    locationSid: "",
    valid: true,
    rules: {
      yyyymmddRequired: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        !value ||
        i18n.tc("check.chk_inputDate"),
      // isNumber: (value) =>
      //   !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
      //   i18n.tc("check.chk_inputNumber"),
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      reservationRequired: (value) => !!value || i18n.tc("check.chk_inputReservationSelect"),
      pickingRequired: (value) => !!value || i18n.tc("check.chk_inputPickingSelect"),
    },
    getSuppliersSelected: "",
    productHintArrival: "",
    searchErrorMsg: "",
    exclusiveReserveUpdateDatetime: "",
    // 引当ボタン押下時の検索条件
    searchParam: {
      // 取引先
      suppliersSelected: "",
      // 出荷予定日
      shipInsDate: "",
      // 引当基準
      reserveBasisSelected: "",
      // ピッキング区分
      pickingSelected: "",
      // 輸送会社
      truckNm: "",
      // 出荷先
      destinationSelected: "",
      // 品質区分
      qualitySelected: "",
      // 納品日
      dateToDelivery: "",
    },
  }),
  methods: {
    append() {
      this.truckKbn = "";
    },

    colsePopup() {
      this.infoDialog.isOpen = false;
      this.totalSearch = 0;
    },
    // 担当者ポップアップ
    openPIC(item, row) {
      this.checkRow = row;
      this.dialogPIC = true;
      item.check = true;
    },
    // 担当者ポップアップデータ取得
    savePIC(data) {
      this.selectPIC = data.name;
      this.selectPICSid = data.value;
      this.inventoryList[this.checkRow].PIC = this.selectPIC;
      for (let i = 0; i < this.inventoryList.length; i++) {
        if (this.inventoryList[i].check === true) {
          this.inventoryList[i].locationUserName = this.selectPIC;
          this.inventoryList[i].locationUserSid = this.selectPICSid;
        }
        this.inventoryList[i].check = false;
      }

      this.dialogPIC = false;
    },
    // 担当者ポップアップ閉じる
    setPopup() {
      this.dialogPIC = false;
    },

    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.truckInfo = value;

      // 画面表示用
      if (!this.truckInfo.carrierCompName && this.truckInfo.carrierCompCd == "04") {
        this.truckNm = i18n.tc("label.lbl_route");
      } else {
        this.truckNm = this.truckInfo.carrierCompName;
      }
      // API検索用
      this.truckKbn = this.truckInfo.carrierCompCd;
      this.truck = this.truckInfo.deliveryTraderSid;
      this.truckName = this.truckInfo.deliveryTraderName;
      this.dialogShpAdress = false;
    },
    /**
     * 配送先選択画面：配送先選択ボタン処理
     */
    openTruck() {
      if (this.suppliersSelected) {
        this.truckInfo.suppliersSelected = this.suppliersSelected;
        //路線業者SID
        this.truckInfo.deliveryTraderSid = this.truck;
        //輸送業者コード
        this.truckInfo.carrierCompCd = this.truckKbn;
        this.dialogShpAdress = true;
        if (this.$refs.truck != undefined) {
          //表示時処理
          this.$refs.truck.init();
        }
      } else {
        this.suppliersSelected = null;
      }
    },

    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.initSimpleDialog();
      if (this.suppliersSelected) {
        this.infoDialog.isOpen = false;
        this.truckNm = "";
        this.suppliersSelected = "";
        this.reserveBasisSelected = "";
        this.pickingSelected = "";
        this.destinationSelected = "";
        this.dateToDelivery = "";
        this.qualitySelected = "01";
        this.inventoryList = [];
        // 引当確定フラグ
        this.confirmFlg = true;

        this.getCustomInfo();
        this.getMstCode();
        // 一覧のバリデーションチェックをリセット
        this.$refs.form.resetValidation();
      } else {
        this.truckNm = "";
        this.suppliersSelected = "";
        this.reserveBasisSelected = "";
        this.pickingSelected = "";
        this.destinationSelected = "";
        this.qualitySelected = "01";

        this.shipInsDate = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.shipInsDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());

        this.dateToDelivery = "";
        this.dateToDeliveryCal = "";

        this.getCustomInfo();
        this.getMstCode();
      }
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 出荷予定日付を－１日します。
     */
    prevDate() {
      if (this.shipInsDateCal == null) {
        return;
      }
      let date = new Date(this.shipInsDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shipInsDateCal != toDate) {
        this.shipInsDateCal = toDate;
      } else {
        this.shipInsDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    /**
     * 出荷予定日付を＋１日します。
     */
    nextDate() {
      if (this.shipInsDateCal == null) {
        return;
      }
      let date = new Date(this.shipInsDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.shipInsDateCal != toDate) {
        this.shipInsDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.shipInsDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * 納品日付を－１日します。
     */
    prevDeliveryDate() {
      if (this.dateToDeliveryCal == null || this.dateToDeliveryCal == "") {
        return;
      }
      let date = new Date(this.dateToDeliveryCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.dateToDeliveryCal != toDate) {
        this.dateToDeliveryCal = toDate;
      } else {
        this.dateToDeliveryCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    /**
     * 納品日付を＋１日します。
     */
    nextDeliveryDate() {
      if (this.dateToDeliveryCal == null || this.dateToDeliveryCal == "") {
        return;
      }
      let date = new Date(this.dateToDeliveryCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.dateToDeliveryCal != toDate) {
        this.dateToDeliveryCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.dateToDeliveryCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力処理
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.shipInsDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.shipInsDateCal = null;
      }
    },
    /**
     * 納品日付入力処理
     */
    changeDeliveryDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.dateToDeliveryCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.dateToDeliveryCal = null;
      }
    },

    // 業務利用関係者マスタAPI (共通API)
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBizAuthority(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(),
        "1"
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // コードマスタAPI (共通API)
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 引当基準
      const reservationList = getParameter.getCodeMst(appConfig.CODETYPE.ALLOCATION_STANDARD);
      // ピッキング
      const pickingTypeList = getParameter.getCodeMst(appConfig.CODETYPE.PICKING_TYPE);
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);

      Promise.all([reservationList, pickingTypeList, qualityList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.reserveBasisList = result[0];
          this.pickingList = result[1];
          this.qualityList = result[2];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 検索ボタン
    search() {
      this.searchErrorMsg = "";
      // 引当ボタン押下時の検索条件を保持
      this.saveSearchCondition();
      if (this.$refs.form.validate()) {
        this.inventoryList = [];
        // バリデーションエラーメッセージを非表示
        this.alertMessage = "";
        this.getApiSearch();
      }
    },
    /**
     * ダイアログを表示する
     */
    viewConfirmDialog(button, confirmMessage = "") {
      let message = "";
      let redMessage = "";
      let action;
      let isChangeFlg;
      let isValidationRequired = false;
      let okBtnTxt = i18n.tc("btn.btn_ok_en");
      let cancelBtnTxt = i18n.tc("btn.btn_cancel");
      let okBtnColor = void 0;
      let isAlert = false;
      let outsideClickNotCloseFlg = false;

      switch (button) {
        case "btn_reservation":
          redMessage = confirmMessage;
          action = () => {
            this.forceInterruptAction(() => {
              this.search();
            }, i18n.tc("btn.btn_reservation"));
          };
          isChangeFlg = true;
          isValidationRequired = true;
          okBtnTxt = i18n.tc("btn.btn_force_interrupt");
          okBtnColor = "#f19ca7";
          isAlert = true;
          outsideClickNotCloseFlg = true;
          break;
        case "btn_reservationConfirm":
          message = messsageUtil.getMessage("P-STK-002_003_E");
          isChangeFlg = true;
          action = this.reservationOk;
          break;
        default:
          message = "";
      }
      if (isValidationRequired && !this.$refs.form.validate()) {
        return;
      }
      this.confirmDialog.firstPageFlag = false;
      if (isChangeFlg) {
        this.confirmDialog.changeFlag = isChangeFlg;
        this.confirmDialog.screenFlag = false;
        this.confirmDialog.redMessage = redMessage;
      } else {
        this.confirmDialog.changeFlag = isChangeFlg;
        this.confirmDialog.screenFlag = true;
      }
      this.confirmDialog.message = message;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.okAction = action;
      this.confirmDialog.cancelBtnTxt = cancelBtnTxt;
      this.confirmDialog.okBtnTxt = okBtnTxt;
      this.confirmDialog.okBtnColor = okBtnColor;
      this.confirmDialog.isAlert = isAlert;
      this.confirmDialog.outsideClickNotCloseFlg = outsideClickNotCloseFlg;
    },
    /**
     * 強制割込み処理を実行する
     * @param {Function} nextTask 割込み処理後に実行する関数
     * @param {*} nextTaskName 業務排他APIのレスポンスJSON
     */
    async forceInterruptAction(nextTask) {
      try {
        const exclusiveReserveResponse = await commonFunction.exclusiveReserve(
          appConfig.SCREEN_ID.P_STK_001,
          appConfig.EXCLUSIVE_RESERVE_PROC_DIV.FORCE_INTERRUPT,
          this.suppliersSelected,
          this.exclusiveReserveUpdateDatetime
        );
        // 最新の排他制御テーブルの更新日時を取得
        this.exclusiveReserveUpdateDatetime =
          exclusiveReserveResponse.resIdv.businessExclution.updateDatetime;
        nextTask();
      } catch (ex) {
        this.infoDialog.message = ex.resCom
          ? messsageUtil.fillMessagePadding(ex.resCom.resComMessage, [
              i18n.tc("label.lbl_reservationInfo"),
            ])
          : ex;
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      }
    },

    // 在庫引当情報取得検索 (在庫引当API GET)
    getApiSearch() {
      this.inventoryList.length = 0;

      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // 共通IF項目 画面ID
      config.params.reqComComponentId = appConfig.SCREEN_ID.P_STK_001;
      // 取引先SID
      config.params.clientSid = this.searchParam.suppliersSelected;
      // 営業所SID（ログインユーザ情報）
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.outListNo = "001";
      // 出荷予定日 基準日時 yyyy/mm/dd hh24:mi:ss.000(UTCに変換)
      config.params.outScheduleDate = dateTimeHelper.convertUTC(this.searchParam.shipInsDate);
      // マスタ取得有無 0：対象言語のみ、1：言語問わず全権取得
      config.params.isMst = "1";
      // 引当基準 -> "01"：入荷日順、"02"：入庫日、"03"：DOT
      config.params.stockAllocation = this.searchParam.reserveBasisSelected;
      // config.params.stockAllocation = "01";
      // ピッキング種別 "01"：シングルピッキング、"02"：トータルピッキング
      config.params.pickingType = this.searchParam.pickingSelected;
      // 路線業者SID（輸送会社名）
      if (this.searchParam.truckKbn != "") {
        config.params.carrierCompCd = this.searchParam.truckKbn;
        if (this.searchParam.truck != "") {
          config.params.deliveryTraderSid = this.searchParam.truck;
        }
      }
      // 出荷先選択の場合
      if (this.searchParam.destinationSelected) {
        // ToSID（出荷先SID）
        config.params.shipSid = this.searchParam.destinationSelected;
      }
      // 品質区分
      if (this.searchParam.qualitySelected) {
        config.params.qualitySelected = this.searchParam.qualitySelected;
      }
      // 納品日 yyyy/mm/dd
      if (this.dateToDelivery) {
        config.params.deliveryDate = this.dateToDelivery;
      }

      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }

      // ダイアログを初期化
      this.initSimpleDialog();
      return new Promise((resolve, reject) => {
        this.$httpClient
          .stepFunctionsGetAsync(appConfig.API_URL.BIZ_STOCKALLOCATION, config.params)
          .then((response) => {
            const jsonData = JSON.parse(response);
            // 正常時
            if (jsonData.resCom.resComCode === appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              let businessExclution = jsonData.resIdv.businessExclution;
              this.exclusiveReserveUpdateDatetime = jsonData.resIdv.businessExclution
                ? businessExclution.updateDatetime
                : null;

              jsonData.resIdv.stocksAllocation.forEach((row) => {
                let lotObj = commonUtil.convertLotNo(row.lotNo);
                // ★ = GET, 画面表示項目
                list.push({
                  check: false, // 選択行
                  pickingType: this.pickingSelected, // ピッキングタイプ
                  outListSid: row.outListSid, // 出荷伝票SID
                  clientSid: row.clientSid, // 取引先SID
                  officeSid: row.officeSid, // 営業所SID
                  shipInsNumber: row.outListNo, // 出荷伝票No ★
                  shipInsDate: dateTimeHelper.convertUTC2JST(row.outScheduleDate).substr(0, 10), // 出荷予定日 ★
                  baseCd: row.itemCd, // 出荷先コード ★
                  destination: row.relatedPartyName, // 出荷先名 ★
                  relatedPartyShort: row.relatedPartyShort, // 出荷先略称
                  freeCostDiv: row.freeCostDiv, // 有償無償区分
                  qualityDiv: row.qualityDiv, // 品質区分
                  outScheduleUpdateDatetime: row.outScheduleUpdateDatetime, // 出荷予定変更日時
                  outListSubSid: row.outListSubSid, // 出荷伝票明細SID
                  outListSubNo: row.outListSubNo, // 出荷伝票明細No
                  itemStandardSid: row.itemStandardSid, // 商品標準SID
                  productCnCd: row.itemCd.substring(0, 50).trim(), // 品番 ★
                  productCnCdShow: row.itemCd, // 品番
                  accessoryProductCd: row.itemCd.slice(-50).trim(), //付帯品番
                  productName: row.itemName, // 品名 ★
                  itemNameShort: row.itemNameShort, // 品名略称
                  lot: lotObj.lot, // ロット、S/N
                  lotShow: row.lotNo, // ロット
                  dueDate: lotObj.dueDate, // 期限日、付属キー１、付属キー２
                  shipQtyDisp: commonUtil.formatToCurrency(Number(row.shipScheduleQuantity)), // 出庫数量表示用 ★
                  shipQty: row.shipScheduleQuantity, // 出庫数量
                  itemManageNo: row.itemManageNo, // 管理No
                  locationSid: row.locationSid, // ロケーションSID
                  warehouseSid: row.warehouseSid, // 倉庫SID
                  warehouse: row.warehouseName, // 倉庫名 ★
                  warehouseNameShort: row.warehouseNameShort, // 倉庫名 ★
                  carrierCompCd: row.carrierCompCd, // 輸送会社区分 ★
                  carrierCompName: row.carrierCompName, // 輸送会社種別名 ★
                  shipCampany: row.deliveryTraderName, // 輸送会社名 ★
                  shipCampanySid: row.deliveryTraderSid, // 輸送会社Sid ★
                  location: row.locationNo, // ロケーションNo ★
                  reserveQtyDisp: commonUtil.formatToCurrency(Number(row.allocatedCnt)), // 引当数表示用 ★
                  reserveQty: row.allocatedCnt, // 引当数
                  unreserveQtyDisp: commonUtil.formatToCurrency(Number(row.allocatedFailedCnt)), // 未引当数表示用 ★
                  unreserveQty: row.allocatedFailedCnt, // 未引当数
                  inListSubSid: row.inListSubSid, // 入荷伝票明細SID
                  dummyItemFlg: row.dummyItemFlg,
                  fromSid: row.fromSid, // 取引先Sid
                  toSid: row.toSid, // 出荷先Sid
                  unreserveQtyResult: "", // 状況
                  deliveryTraderNameShort: row.deliveryTraderNameShort, // 輸送会社名略称
                  deliveryTraderSid: row.deliveryTraderSid, // 輸送会社Sid
                  // locationUserSid: this.selectPICSid, // ロケーション担当者SID
                  // locationUserName: this.selectPIC, // ロケーション担当者名
                  // PIC: row.selectPIC,
                  locationUserSid: this.selectPICSid, // ロケーション担当者SID
                  locationUserName: this.selectPIC, // ロケーション担当者名
                  PIC: row.locationUserName, // 担当者名
                  dateToDelivery: row.deliveryDate, // 納品日 ★
                  stocksAllocatedFailedCntItemCd: row.stocksAllocatedFailedCntItemCd,
                  blameDiv: row.blameDiv,
                  outScheduleDetailUpdateDatetime: row.outScheduleDetailUpdateDatetime,
                  wareHouseTransfer: row.wareHouseTransfer, // はい替え中不可フラグ
                });
              });

              for (let i = 0; i < list.length; i++) {
                if (list[i].unreserveQty != 0) {
                  this.unreserveQtyResult = i18n.tc("label.lbl_unavailableReserve");
                } else if (list[i].dummyItemFlg == 1) {
                  this.unreserveQtyResult = i18n.tc("label.lbl_dummyAddItemReserve");
                } else if (list[i].wareHouseTransfer != 0) {
                  this.unreserveQtyResult = i18n.tc("label.lbl_unavailableWareHouseTransfer");
                } else {
                  this.unreserveQtyResult = i18n.tc("label.lbl_availableReserve");
                }

                list[i].unreserveQtyResult = this.unreserveQtyResult;
              }
              this.inventoryList = list;

              // 検索結果の件数
              this.totalSearch = list.length;

              resolve(response);
              // エラー時
            } else {
              if (this.inventoryList.length == 0) {
                this.inventoryList = [];
                this.totalSearch = 0;
              }
              // エラーメッセージをpopupのmessageに格納
              if (commonFunction.isExclusiveReserveNeedInterrupt(jsonData)) {
                // 排他DBの更新日時を取得する
                this.exclusiveReserveUpdateDatetime =
                  jsonData.resIdv.businessExclution.updateDatetime;
                this.viewConfirmDialog("btn_reservation", jsonData.resCom.resComMessage);
                resolve();
              } else {
                this.infoDialog.message = messsageUtil.fillMessagePadding(
                  jsonData.resCom.resComMessage,
                  [i18n.tc("label.lbl_reservationInfo")]
                );
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            }
          })
          .catch((ex) => {
            if (
              ex.message == "timeout of 30000ms exceeded" ||
              ex.message == "Request failed with status code 504"
            ) {
              console.error("getApiSearch() Resolve", resolve);
              this.timeoutDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-STK-001_002_E");
              reject(resolve);
            } else {
              console.error("getApiSearch() Resolve", resolve);
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            }
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 引当確定ボタン
    Confirm() {
      this.confirmDialog.firstPageFlag = false;
      this.confirmDialog.changeFlag = true;
      this.confirmDialog.message = messsageUtil.getMessage("P-STK-002_003_E");
      this.confirmDialog.isOpen = true;
    },
    // 引当確定 (OKの場合)
    reservationOk() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();
      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STK_001;

      // 在庫引当保管List
      let bodyList = [];
      for (let i = 0; i < this.inventoryList.length; i++) {
        if (
          this.inventoryList[i].unreserveQty == 0 &&
          this.inventoryList[i].dummyItemFlg == 0 &&
          this.inventoryList[i].wareHouseTransfer == 0
        ) {
          let inputMap = {};

          inputMap.outListSid = this.inventoryList[i].outListSid;
          inputMap.clientSid = this.suppliersSelected;
          inputMap.officeSid = this.inventoryList[i].officeSid;
          inputMap.outListNo = this.inventoryList[i].shipInsNumber;
          inputMap.outScheduleDate = dateTimeHelper.convertUTC(this.shipInsDate);
          inputMap.itemCd = this.inventoryList[i].baseCd;
          inputMap.relatedPartyName = this.inventoryList[i].destination;
          inputMap.relatedPartyShort = this.inventoryList[i].relatedPartyShort;
          inputMap.freeCostDiv = this.inventoryList[i].freeCostDiv;
          inputMap.qualityDiv = this.inventoryList[i].qualityDiv;
          inputMap.outScheduleUpdateDatetime = this.inventoryList[i].outScheduleUpdateDatetime;
          inputMap.outListSubSid = this.inventoryList[i].outListSubSid;
          inputMap.outListSubNo = this.inventoryList[i].outListSubNo;
          inputMap.itemStandardSid = this.inventoryList[i].itemStandardSid;
          inputMap.itemCd = this.inventoryList[i].productCnCdShow;
          inputMap.itemName = this.inventoryList[i].productName;
          inputMap.itemNameShort = this.inventoryList[i].itemNameShort;
          inputMap.lotNo = this.inventoryList[i].lotShow;
          inputMap.shipScheduleQuantity = this.inventoryList[i].shipQty;
          inputMap.itemManageNo = this.inventoryList[i].itemManageNo;
          inputMap.locationSid = this.inventoryList[i].locationSid;
          inputMap.warehouseSid = this.inventoryList[i].warehouseSid;
          inputMap.locationNo = this.inventoryList[i].location;
          inputMap.allocatedCnt = this.inventoryList[i].reserveQty;
          inputMap.allocatedFailedCnt = this.inventoryList[i].unreserveQty;
          inputMap.fromSid = this.inventoryList[i].fromSid;
          inputMap.toSid = this.inventoryList[i].toSid;
          inputMap.deliveryTraderSid = this.inventoryList[i].shipCampanySid;
          inputMap.deliveryTraderName = this.inventoryList[i].shipCampany;
          inputMap.deliveryTraderNameShort = this.inventoryList[i].deliveryTraderNameShort;
          inputMap.stocksAllocatedFailedCntItemCd =
            this.inventoryList[i].stocksAllocatedFailedCntItemCd;
          inputMap.warehouseName = this.inventoryList[i].warehouse;
          inputMap.warehouseNameShort = this.inventoryList[i].warehouseNameShort;
          inputMap.inListSubSid = this.inventoryList[i].inListSubSid;
          inputMap.locationUserSid = this.inventoryList[i].locationUserSid; //ロケーション担当者SID
          inputMap.locationUserName = this.inventoryList[i].PIC;
          inputMap.blameDiv = this.inventoryList[i].blameDiv;
          inputMap.outScheduleDetailUpdateDatetime =
            this.inventoryList[i].outScheduleDetailUpdateDatetime;
          bodyList.push(inputMap);
        }
      }
      let allocation = {
        pickingType: this.pickingSelected,
        opeDispName: i18n.tc("label.lbl_inventoryReservationPc"),
        stocksAllocation: bodyList,
      };
      let exclusion = {
        procDiv: appConfig.EXCLUSIVE_RESERVE_PROC_DIV.LOCK,
        clientSid: this.suppliersSelected,
        updateDatetime: this.exclusiveReserveUpdateDatetime,
      };
      body.reqIdv.allocation = allocation;
      body.reqIdv.exclusion = exclusion;

      this.initSimpleDialog();
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .stepFunctionsPostAsync(appConfig.API_URL.BIZ_STOCKALLOCATION, body)
          //.securePost(appConfig.API_URL.BIZ_STOCKALLOCATION, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(response);
            // 正常時
            if (jsonData.resCom.resComCode === appConfig.RESCOMCODE_SUCCESS) {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              // 画面遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.InvResFlg = true;
              this.infoDialog.resConFlg = true;
              this.infoDialog.firstPageFlag = false;
              this.infoDialog.outsideClickNotCloseFlg = true;

              // エラー時
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              if (appConfig.API_ERROR_MSG_ID.A_999_999_020_E === jsonData.resCom.resComMessageId) {
                // 排他エラーの場合は、最新の引当情報を再取得
                this.infoDialog.okAction = this.search;
                this.infoDialog.isRequireOkActionWhenpressOkBtn = true;
                this.infoDialog.message = messsageUtil.fillMessagePadding(
                  jsonData.resCom.resComMessage,
                  [i18n.tc("label.lbl_reservationInfo")]
                );
              }
            }

            resolve(response);
            this.totalSearch = 0;
            if (bodyList.length == 0) {
              this.inventoryList = [];
              bodyList = [];
              this.totalSearch = 0;
              this.$refs.form.resetValidation();
            }
          })
          .catch((ex) => {
            if (
              ex.message == "timeout of 30000ms exceeded" ||
              ex.message == "Request failed with status code 504"
            ) {
              console.error("reservationOk() Resolve", resolve);
              this.timeoutDialogMessage = true;
              this.backMessage = messsageUtil.getMessage("P-STK-001_003_E");
              reject(resolve);
            } else {
              console.error("reservationOk() Resolve", resolve);
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(resolve);
            }
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    /**
     * 引当基準コードリストボックス取得処理
     */
    setReserveBasis() {
      for (var i = 0; i < this.reserveBasisList.length; i++) {
        if (this.reserveBasisList[i].value == this.reserveBasisSelected) {
          return this.reserveBasisList[i].name;
        }
      }
    },
    /**
     * ピッキングコードリストボックス取得処理
     */
    setPicking() {
      for (var i = 0; i < this.pickingList.length; i++) {
        if (this.pickingList[i].value == this.pickingSelected) {
          return this.pickingList[i].name;
        }
      }
    },
    /**
     * 出荷先コードリストボックスセット処理
     */
    setStsArrival() {
      for (var i = 0; i < this.destinationSelectedList.length; i++) {
        if (this.destinationSelectedList[i].value == this.destinationSelected) {
          return this.destinationSelectedList[i].name;
        }
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.destinationSelectedList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintArrival = val.displayText;
        this.getSuppliersSelected = val.displayText;
      }
      this.destinationSelectedList = this.destinationSelectedList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintArrival = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 引当確定ボタンの活性/非活性制御
     */
    isReservationConfirmBtnDisabled() {
      return (
        this.inventoryList.length === 0 ||
        this.inventoryList.length ===
          this.inventoryList.filter((element) => {
            return (
              element.unreserveQty !== 0 ||
              element.dummyItemFlg == 1 ||
              element.wareHouseTransfer != 0
            );
          }).length
      );
    },
    /**
     * 引当ボタン押下時の検索条件を保持する
     */
    saveSearchCondition() {
      this.searchParam.suppliersSelected = this.suppliersSelected;
      this.searchParam.shipInsDate = this.shipInsDate;
      this.searchParam.reserveBasisSelected = this.reserveBasisSelected;
      this.searchParam.pickingSelected = this.pickingSelected;
      this.searchParam.truckKbn = this.truckKbn;
      this.searchParam.truck = this.truck;
      this.searchParam.destinationSelected = this.destinationSelected;
      this.searchParam.qualitySelected = this.qualitySelected;
    },
    /**
     * ダイアログを初期化する
     */
    initSimpleDialog() {
      this.infoDialog = Object.assign({}, this.defaultInfoDialog);
      this.infoDialog.okAction = () => {
        this.init();
      };
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 引当確定後の場合
      if (this.confirmFlg) {
        this.suppliersSelected = "";
        this.inventoryList = [];
        // 引当確定フラグ
        this.confirmFlg = false;
      }
      // 変更検知用の設定
      this.shipInsDateCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    /**
     * OKボタン処理
     */
    okClicked() {
      this.timeoutDialogMessage = false;
    },
  },
  computed: {},
  watch: {
    //取引先変更時
    suppliersSelected() {
      //輸送会社クリア
      this.truckNm = "";
      this.truckKbn = "";
      this.truck = "";
      //出荷先リストクリア
      this.destinationSelected = "";
      this.destinationSelectedList = [];
    },

    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    shipInsDateCal: {
      handler(val) {
        this.shipInsDate = this.formatDate(val);
      },
      deep: true,
    },
    dateToDeliveryCal: {
      handler(val) {
        this.dateToDelivery = this.formatDate(val);
      },
      deep: true,
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (this.inventoryList.length === 0) {
          return;
        }
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_STK_001_SORT[sortBy].replaceAll("{0}", ascdesc);
          this.getApiSearch(true);
        }
      },
      deep: true,
    },
    // 出荷先取得
    getSuppliersSelected(val) {
      if (val ?? false) {
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          this.destinationSelectedList = [];
          this.destinationSelected = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintArrival) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          let officeCd = this.supplierList.find(
            (element) => element.value === this.suppliersSelected
          ).clientSid;
          // To（出荷先）取得
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(),
            undefined,
            officeCd,
            val
          );
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.destinationSelectedList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.destinationSelectedList.length = 0;
          this.destinationSelectedList = [];
        }
      }
    },
  },

  mounted() {
    // バリデーションをリセット
    // this.$refs.form.resetValidation();
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
//引当ボタン 左端個別合わせ
.reservation-btn {
  margin-right: 3%;
  width: 7rem;
}
.paging-position {
  margin-left: 4.5%;
}

.paging-label {
  width: 100%;
  padding-top: 0.5rem;
  padding-left: 1.7rem;
  text-align: right;
  font-size: large;
  color: rgba(0, 0, 0, 0.6);
}

// トータル検索数
.search-result {
  margin: 0 0 0 5%;
}

.required::after {
  content: " *";
  color: red;
  vertical-align: middle;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

.text-overflow {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.first-btn-search {
  margin-left: -0.7%;
}
#listData ::v-deep th,
#listData ::v-deep td {
  padding: 0 8px;
}
</style>
