<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <!-- BOM一覧 -->
      <NavBar tittle="label.lbl_bomMstList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                pa-3
                disabled
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="item-spacer">&nbsp;</span>
          </v-row>
          <v-row style="padding-top: 10px">
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 戻るボタン -->
                <v-btn class="other-btn" style="float: right" @click="returnIniMasterReg">{{
                  $t("btn.btn_back")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
          <!-- ここから二行目 -->
        </v-container>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
            :options.sync="sortOptions"
            :hide-default-footer="true"
            height="480px"
            style="word-break: break-all"
          >
            <!-- 完成品番/品名 -->
            <template v-slot:[`header.finishedProductItemCd`]="{}">
              {{ $t("label.lbl_finishedProductItemCdName") }}
            </template>
            <template v-slot:[`item.finishedProductItemCd`]="{ item }">
              <div>
                <div>{{ item.finishedProductItemCd }}</div>
                <div>{{ item.finishedProductItemName }}</div>
              </div>
            </template>
            <!-- 親品番/品名 -->
            <template v-slot:[`header.itemCdL1`]="{}">
              {{ $t("label.lbl_itemCdName1") }}
            </template>
            <template v-slot:[`item.itemCdL1`]="{ item }">
              <div>
                <div>{{ item.itemCdL1 }}</div>
                <div>{{ item.itemNameL1 }}</div>
              </div>
            </template>
            <!-- 子品番/品名 -->
            <template v-slot:[`header.itemCdL2`]="{}">
              {{ $t("label.lbl_itemCNamed2") }}
            </template>
            <template v-slot:[`item.itemCdL2`]="{ item }">
              <div>
                <div>{{ item.itemCdL2 }}</div>
                <div>{{ item.itemNameL2 }}</div>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>
      </v-form>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="postApi"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :okAction="postApi"
        :firstPageFlag="infoDialog.firstPageFlag"
        :locaMstFlg="infoDialog.locaMstFlg"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import ConfirmDialog from "../../components/ConfirmDialog";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_MST_018,
  components: {
    ConfirmDialog,
    Loading,
    SimpleDialog,
    sideMenu,
    CheckDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    //取引先
    suppliersSelected: "",
    supplierList: [],
    //取引先・拠点コード
    clientBaseCode: "",
    clientBaseName: i18n.tc("label.lbl_supplier") + "/" + i18n.tc("label.lbl_baseCd"),
    clientBaselength: "0",
    //取引先・拠点名称
    clientBaseNm: "",
    clientBaselabel: "",
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // インデックス番号
    index: 0,
    // メニュー
    openMenu: null,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    inputList: [],
    // 初期データ
    defaultData: [],
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 1,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // 検索条件保持
    relatedPartiesKbnSearch: "",
    clientKbnSearch: "",
    suppliersSelectedSearch: "",
    clientBaseCodeSearch: "",
    clientBaseNmSearch: "",
    postSearch: "",
    // テーブルヘッダ項目
    headerItems: [
      // 完成品番
      {
        text: i18n.tc("label.lbl_finishedProductItemCd"),
        value: "finishedProductItemCd",
        width: "13%",
        sortable: false,
        align: "left",
      },
      // 完成品番付帯
      {
        text: i18n.tc("label.lbl_finishedProductItemCdAccessory"),
        value: "finishedProductItemCdAccessory",
        width: "13%",
        sortable: false,
        align: "left",
      },
      // 親品番
      {
        text: i18n.tc("label.lbl_itemCd1"),
        value: "itemCdL1",
        width: "13%",
        sortable: false,
        align: "left",
      },
      // 親品番付帯
      {
        text: i18n.tc("label.lbl_itemCd1Accessory"),
        value: "itemCdL1Accessory",
        width: "13%",
        sortable: false,
        align: "left",
      },
      // 子品番
      {
        text: i18n.tc("label.lbl_itemCd2"),
        value: "itemCdL2",
        width: "13%",
        sortable: false,
        align: "left",
      },
      // 子品番付帯
      {
        text: i18n.tc("label.lbl_itemCd2Accessory"),
        value: "itemCdL2Accessory",
        width: "13%",
        sortable: false,
        align: "left",
      },
      // 構成数
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "composeQuantity",
        width: "11%",
        sortable: false,
        align: "right",
      },
      // 使用工程
      {
        text: i18n.tc("label.lbl_opeHist"),
        value: "opeHist",
        width: "11%",
        sortable: false,
        align: "left",
      },
    ],
  }),
  methods: {
    // ページング
    u_100_check() {
      if (this.itemsPerPage < 101) {
        this.u_10 = true;
      }
    },
    u_100_on() {
      this.u_10 = true;
      this.itemsPerPage = 100;
      this.bomListSearch("get-search");
    },
    u_100_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.bomListSearch("get-search");
    },
    // 初期化
    init() {
      this.loadingCounter = 1;
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      //取引先、コードマスタ取得
      this.getCodeMst();

      // 前画面からのパラメータ設定
      this.suppliersSelected = this.$route.params.suppliersSelected;

      this.bomListSearch("get-search");
    },

    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    // コードマスタ
    getCodeMst() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(null)
      );

      Promise.all([clientList])
        .then((result) => {
          this.supplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 検索ボタン
     */
    bomListSearch(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();

      config.params.clientSid = this.$route.params.suppliersSelected;
      config.params.itemStandardSid = this.$route.params.productCodeSelected;

      if (val == "get-search") {
        config.params.reqComPageIndex = 1;
        this.page = 1;
      } else {
        // ページャー処理
        if (this.page >= 2 && this.inputList.length == 0) {
          config.params.reqComPageIndex = 1;
          this.page = 1;
        } else {
          config.params.reqComPageIndex = this.page;
        }
      }

      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_BOM_LIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.relatedPartys.forEach((row) => {
                list.push({
                  finishedProductItemCd: row.finishedProductItemCd,
                  finishedProductItemCdAccessory: row.finishedProductItemCdAccessory,
                  finishedProductItemName: row.finishedProductItemName,
                  itemCdL1: row.itemCdL1,
                  itemCdL1Accessory: row.itemCdL1Accessory,
                  itemNameL1: row.itemNameL1,
                  itemCdL2: row.itemCdL2,
                  itemCdL2Accessory: row.itemCdL2Accessory,
                  itemNameL2: row.itemNameL2,
                  composeQuantity: Number(row.composeQuantity),
                  opeHist: row.procCd,
                });
              });
              this.inputList = list;
              this.totalCount = commonUtil.formatToCurrency(Number(jsonData.resCom.resComCount));
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    postApi() {
      //
    },

    /**
     * 戻る
     */
    returnIniMasterReg() {
      // 一覧画面へ遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_INI_008,
        params: {
          userMakerKbn: this.$route.params.userMakerKbn, // ユーザーメーカー区分
          supplier: this.$route.params.supplier, //取引先
          itemCd: this.$route.params.itemCd, //品番
          itemSubCd: this.$route.params.itemSubCd, //付帯品番
          name: appConfig.SCREEN_ID.P_INI_009, //遷移後再検索用共通値
        },
      });
    },
  },
  mounted() {
    this.init();
  },
  computed: {},
  watch: {
    page: function (newValue) {
      this.page = newValue;
      this.bomListSearch("");
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MST_018_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.bomListSearch();
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.search-textbox-remark {
  width: 41.5rem;
  margin-left: 15px;
}

.calender {
  display: flex;
}

// 実行ボタンがある行
.search-row-exeBtns {
  width: 100%;
  margin-right: 12px;
}

// 検索項目間の空白
.item-spacers {
  margin-left: 50px;
  margin-right: 6px;
}

.isD {
  background-color: $color-warning !important;
}

.locationAlign {
  margin: 9px 0 0 0;
  height: 3.6rem;
  text-align: left;
}

// #listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody td {
//   vertical-align: top;
// }
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody td.vertical {
  vertical-align: top;
}
</style>
