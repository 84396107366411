import { render, staticRenderFns } from "./StockInquiry.vue?vue&type=template&id=89343fd2&scoped=true"
import script from "./StockInquiry.vue?vue&type=script&lang=js"
export * from "./StockInquiry.vue?vue&type=script&lang=js"
import style0 from "./StockInquiry.vue?vue&type=style&index=0&id=89343fd2&prod&lang=css"
import style1 from "./StockInquiry.vue?vue&type=style&index=1&id=89343fd2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89343fd2",
  null
  
)

export default component.exports